import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Generateticket.css';
import { generateTicketApi } from '../AllApi/AllApi';
import toast, { Toaster } from 'react-hot-toast';
import { decryptData, Domain_Url, isAuthenticated, isUserId } from '../components/Url/Url';

const Generateticket = () => {
  const token = isAuthenticated; // Use `isAuthenticated` as a value
  const userId = isUserId; // Fetch the user ID
  const navigate = useNavigate();
  // Redirect if token or userId is null
  useEffect(() => {
    if (token === null || userId === null) {
      navigate("/");
    }
  }, [token, userId, navigate]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    subject: '',
    description: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting form data:', formData); // Debugging log
      const response = await generateTicketApi(formData);
      console.log('API response:', response); // Debugging log
      if (response.error) {
        throw new Error(response.error);
      }
      toast.success('Ticket created successfully!');
      setFormData({
        name: '',
        email: '',
        mobile: '',
        subject: '',
        description: ''
      });
    } catch (error) {
      console.error('Error creating ticket:', error); // Debugging log
      toast.error('Failed to create ticket. Please try again.');
    }
  };

  return (
    <>
      <Header />
      <section className="generateticket_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="flex_generating_tkt">
                <h3>New Ticket</h3>
                <Link to="/ticket">My Ticket</Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 col-sm-12 col-12">
              <div className="generating_form_div">
                <form className="row" onSubmit={handleSubmit}>
                  <div className="col-md-12 mt-1">
                    <label htmlFor="name" className="form-label">Name <span style={{color: 'red'}}>*</span></label>
                    <div className="input-group">
                      <input type="text" className="form-control" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
                    </div>
                  </div>
                  <div className="col-md-12 mt-1">
                    <label htmlFor="email" className="form-label">Email <span style={{color: 'red'}}>*</span></label>
                    <div className="input-group">
                      <input type="email" className="form-control" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                    </div>
                  </div>
                  <div className="col-md-12 mt-1">
                    <label htmlFor="mobile" className="form-label">Mobile No. <span style={{color: 'red'}}>*</span></label>
                    <div className="input-group">
                      <input type="text" className="form-control" name="mobile" maxLength={10} placeholder="Mobile No." value={formData.mobile} onChange={handleChange} required />
                    </div>
                  </div>
                  <div className="col-md-12 mt-1">
                    <label htmlFor="subject" className="form-label">Subject <span style={{color: 'red'}}>*</span></label>
                    <div className="input-group">
                      <select className="form-control" name="subject" value={formData.subject} onChange={handleChange} required>
                        <option value="" disabled>Select your issue</option>
                        <option value="First Free Session">First Free Session</option>
                        <option value="Too much wait time">Too much wait time</option>
                        <option value="Unhappy with the free session">Unhappy with the free session</option>
                        <option value="Missed the session">Missed the session</option>
                        <option value="Payment Related">Payment Related</option>
                        <option value="Previous Order">Previous Order</option>
                        <option value="Astrologer didn't answer">Astrologer didn't answer</option>
                        <option value="Got a different prediction">Got a different prediction</option>
                        <option value="Remedies Issue">Remedies Issue</option>
                        <option value="Technical issue">Technical issue</option>
                        <option value="Offers & Coupons">Offers & Coupons</option>
                        <option value="General FAQs">General FAQs</option>
                        <option value="Some other issues">Some other issues</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12 mt-1">
                    <label htmlFor="description" className="form-label">Description <span style={{color: 'red'}}>*</span></label>
                    <div className="input-group">
                      <textarea rows={3} className="w-100" id="description" name="description" placeholder="Description" value={formData.description} onChange={handleChange} required />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="generating_btn">
                      <button type="submit" name="generate">Create Ticket</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Toaster />
    </>
  );
};

export default Generateticket;