import React, { useState } from 'react';
import axios from 'axios';
import { isAuthenticated } from '../components/Url/Url';

const PaymentComponent = () => {
    const callbackurl=process.env.REACT_APP_DOMAIN_URL+'/wallet';
  const cancelurl=process.env.REACT_APP_DOMAIN_URL+'/wallet'
    const [payment, setPayment] = useState('');
    const API_URL=process.env.REACT_APP_API_URL
    const [userId, setUserId] = useState('');
   const authentication=isAuthenticated;
   console.warn(authentication)
    const handlePayment = async () => {
        // Load the Razorpay script
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        try {
            // Create a new order on the backend
            const { data } = await axios.post(
                `${API_URL}/razorpayAPIs/create-order`,
                { payment, userId }, // Make sure your backend expects these fields
                {
                    headers: {
                        apiKey: process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${isAuthenticated}`, // Call the function to get the token
                    },
                }
            );

            // Configure Razorpay options
            const options = {
                key: process.env.REACT_APP_RAZORPAY_TEST_KEY,
      
                amount: data.amount, // Amount in paise (as returned by your backend)
                
                currency: 'INR',
                name: 'Talkndheal',
                description: 'Payment for Order',
                order_id: data.order_id, // Use the order_id returned from the backend
                handler: async (response) => {
                    console.log(response, 'rzp'); // Log the full response object for debugging

                    try {
                        // Verify payment on backend
                        const verifyResponse = await axios.post(
                            `${API_URL}/razorpayAPIs/verify-payment`,
                            {
                                razorpay_order_id: response.razorpay_order_id,
                                razorpay_payment_id: response.razorpay_payment_id,
                                razorpay_signature: response.razorpay_signature,
                                user_id: userId,
                            },
                            {
                                headers: {
                                    apiKey: process.env.REACT_APP_API_KEY,
                                    Authorization: `Bearer ${isAuthenticated}`, // Call the function to get the token
                                },
                            }
                        );
                        console.log(verifyResponse.data); // Log the response from the verification API
                        alert('Payment verified successfully');
                    } catch (error) {
                        console.error(error.response || error.message); // Log the full error for debugging
                        alert('Payment Verification Failed');
                    }
                },
                prefill: {
                    name: 'User Name', // Replace with actual user info
                    email: 'user@example.com', // Replace with actual user info
                    contact: '1234567890', // Replace with actual user info
                },
                notes: {
                    'shipping address': 'User Address', // Replace with actual user info
                },
                theme: {
                    color: '#F37254',
                },
                callback_url: callbackurl,
                cancel_url: cancelurl,
            };

            // Initialize and open the Razorpay checkout
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.error(error.response || error.message); // Log the full error for debugging
            alert('Error in creating order');
        }
    };

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    }


    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    }

    return (
        <div>
            <h2>Pay with Razorpay</h2>
            <input
                type="number"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                placeholder="Enter User Id"
            />
            <input
                type="number"
                value={payment}
                onChange={(e) => setPayment(e.target.value)}
                placeholder="Enter Payment Amount"
            />
            <button onClick={handlePayment}>Pay Now</button>
        </div>
    );
};

export default PaymentComponent;
