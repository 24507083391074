function LoadingComponent() {
   
  
    return (
      <>
       <div className="d-flex gap-3 align-items-center justify-content-center fs-1 text-danger" style={{height:"100vh"}}>   
     <img src={require('../assets/img/preloaderDesign.gif')} alt='loader' height={300} width={300}></img>
      </div>
      </>
    );
  }
  
  export default LoadingComponent;
  