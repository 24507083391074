import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../CssFiles/Userprofile.css";
import { getUserDetailsApi } from "../AllApi/AllApi";
import { decryptData, isAuthenticated, isUserId } from "../components/Url/Url";
import LoadingUserProfile from "../components/LoadingComponent/LoadingUserProfile";

const Userprofile = () => {
  const token = isAuthenticated; // Use `isAuthenticated` as a value
  const userId = isUserId; // Fetch the user ID
  const navigate = useNavigate();
  // Redirect if token or userId is null
  useEffect(() => {
    if (token === null || userId === null) {
      navigate("/");
    }
  }, [token, userId, navigate]);
  const [loginRes, setLoginRes] = useState("");
  const [loginResUrl, setLoginResUrl] = useState("");
  const [loading, setLoading] = useState(true);

  const formatAmount = (amount) => {
    if (isNaN(amount) || amount === null || amount === undefined) {
      return "-";
    }
    return new Intl.NumberFormat("en-IN").format(amount);
  };

  async function userDetails() {
    const decToken = isAuthenticated;
    const userId = isUserId;

    const res = await getUserDetailsApi(decToken, userId);
    console.log(res);
    setLoginRes(res);
    setLoginResUrl(res?.url);
    setLoading(false);
  }
  useEffect(() => {
    userDetails();
  }, []);

  return (
    <>
      {/* ========= */}
      <Header />
      {/* ====== */}
      {/* userprofile section start */}
      {!loading ? ( <section className="userprofile_section">
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-4 col-md-5 col-sm-12 col-12">
              <div className="userprofile_img_box">
                <img
                  // src={`${loginRes?.data?.profile}`}
                  // src={profilePreview || "../images/a1.png"}
                  src={`${loginRes?.data?.profile}` ?  loginRes?.data?.profile : "../images/a1.png"}
                  alt={loginRes?.data?.name}
                  className="img-fluid"
                />
              </div>
            </div>
            {/* col end */}

            {/* col start */}
            <div className="col-lg-8 col-md-7 col-sm-12 col-12">
              <div className="userprofile_details_main_div">
                {/*  */}
                <div className="user_profile_name_div">
                  <h1>
                    {loginRes?.data?.title} {loginRes?.data?.name}
                  </h1>
                  <Link to="/editprofile">Edit</Link>
                </div>
                {/*  */}
                {/* user profile dt table start */}
                <div className="userprofile_table_main_div">
                  <table class="table table-bordered table-striped">
                    <tr>
                      <th>Mobile Number:</th>
                      <td>+91 {loginRes?.data?.mobile} </td>
                    </tr>
                    <tr>
                      <th>Email:</th>
                      <td>{loginRes?.data?.email}</td>
                    </tr>
                    <tr>
                      <th>date of birth:</th>
                      <td>
                        {loginRes?.data?.day}-{loginRes?.data?.month}-
                        {loginRes?.data?.year}
                      </td>
                    </tr>
                    <tr>
                      <th>time of birth:</th>
                      <td>
                        {loginRes?.data?.hour}:{loginRes?.data?.minute}:
                        {loginRes?.data?.second}{" "}
                      </td>
                    </tr>
                    <tr>
                      <th>place of birth:</th>
                      <td>{loginRes?.data?.PlaceOfBirth} </td>
                    </tr>
                    <tr>
                      <th>gender:</th>
                      <td>{loginRes?.data?.gender} </td>
                    </tr>
                    <tr>
                      <th>Marital Status:</th>
                      <td>{loginRes?.data?.maritalstatus} </td>
                    </tr>
                    <tr>
                      <th>Occupation:</th>
                      <td>{loginRes?.data?.occupation} </td>
                    </tr>
                  </table>

                  {/* <p>talk to our astrologers now !!!</p> */}
                </div>
                {/* user profile dt table end */}

                {/* <div class="user_btn_div">
                        <Link to="/Call" class="call"><i class="fas fa-phone"></i> call</Link>
                        <Link to="/Chat" class="chat"><i class="fas fa-message"></i> chat</Link>
                        <Link to="/Video" class="call"><i class="fas fa-mobile"></i> video </Link>
                     </div> */}
              </div>
              {/**/}
              {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
  <div className="user_profile_table">
    <h3>My Following</h3>
    <a href="https://varta.healfate.com/astrologersDetails/10010">
    </a><div className="profile-user_m_div_f"><a href="https://varta.healfate.com/astrologersDetails/10010">
        <div className="pro_file_icon_img_user">
          <img src="https://healfate.com/astro_docs//7301image (18).png" alt='Acharya Shashi' className="img-fluid" />
          <div className="profle_name_div">
            <h4>Acharya Shashi</h4>
            <span>Yoga mudras,  Pranayama,  Dietician,  Va</span> 
          </div>
        </div>
      </a><div className="pro_file_dots"><a href="https://varta.healfate.com/astrologersDetails/10010">
        </a><li className="dropdown"><a href="https://varta.healfate.com/astrologersDetails/10010">
          </a><a className="fa fa-ellipsis-v" href="#" data-bs-toggle="dropdown" />
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="user-profile?followid=NjQ=">Unfollow</a></li> 
          </ul>
        </li>
      </div>
    </div>
  </div>
</div> */}
              {/**/}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
      </section>) : (<LoadingUserProfile/>) }
     
      {/* userprofile section end */}
      <Footer />
    </>
  );
};

export default Userprofile;
