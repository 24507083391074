
export const SliderComponentsData = [
   // Best India Astro Kundli slider start=====================
   {
   id: 1,
   slidername:'bestindiakundli',
   image: '../images/ser1.png',
   kundliname: "Kundli Report",
   link:"/freeKundali"
   },
   
   {
   id: 2,
   slidername:'bestindiakundli',
   image: '../images/ser2.png',
   kundliname: "Kundli Matching",
   link:"/kundliMatching"
   },
   
   
   {
      id: 6,
      slidername:'bestindiakundli',
      image: '../images/pan2.png',
      kundliname: "Panchang",
      link:"/panchang"
      },
      
   {
   id: 3,
   slidername:'bestindiakundli',
   image: '../images/ser3.png',
   kundliname: "Horoscope",
   link:"/horoscope/today-horoscope"
   },
   
   {
   id: 4,
   slidername:'bestindiakundli',
   image: '../images/ser4.png',
   kundliname: "Numerology",
   link:"/Numerology"
   },
   
   {
   id: 5,
   slidername:'bestindiakundli',
   image: '../images/ser5.png',
   kundliname: "Lal Kitab",
   link:"/services/Lal-Kitab"
   },

   
   // Best India Astro Kundli slider end=====================

   // Hero slider start=====================
   {
       id: 6,
       slidername:'herosliderdesktop',
       image: '../banner/1.png'
    },

    {
       id: 7,
       slidername:'herosliderdesktop',
       image: '../banner/2.png'
    },

    {
       id: 8,
       slidername:'herosliderdesktop',
       image: '../banner/3.png'
    },

    {
       id: 9,
       slidername:'herosliderdesktop',
       image: '../banner/4.png'
    },

   // Hero  slider end=====================

   
   // Hero slider mobile start=====================
   {
       id: 9,
       slidername:'heroslidermobile',
       image: '../banner/m1.png'
    },

    {
       id: 10,
       slidername:'heroslidermobile',
       image: '../banner/m2.png'
    },

    {
       id: 11,
       slidername:'heroslidermobile',
       image: '../banner/m3.png'
    },

    {
       id: 12,
       slidername:'heroslidermobile',
       image: '../banner/m4.png'
    },

   // Hero  slider mobile end=====================

   // India's Best Astrologers end=====================
   {
     id: 13,
     bestindiaAstroname:'bestindiaAstrologer',
     image: '../images/cta1.png',
     astrologerbest: "Talk To Astrologers",
      link: "/call"
     },
     
     {
     id: 14,
     bestindiaAstroname:'bestindiaAstrologer',
     image: '../images/cta2.png',
     astrologerbest: "Chat With Astrologers",
     link: "/chat"
     },
     
     {
     id: 15,
     bestindiaAstroname:'bestindiaAstrologer',
     image: '../images/cta3.png',
     astrologerbest: "Video to Astrologer",
     link: "/video"
     },
     
     {
     id: 16,
     bestindiaAstroname:'bestindiaAstrologer',
     image: '../images/cta4.png',
     astrologerbest: "Daily Panchang",
     link: "/panchang"
     },
     
     {
     id: 17,
     bestindiaAstroname:'bestindiaAstrologer',
     image: '../images/cta5.png',
     astrologerbest: "Samuhik Puja",
     link: "/Samuhikpuja"
     },

     
     {
        id: 18,
        bestindiaAstroname:'bestindiaAstrologer',
        image: '../images/cta6.png',
        astrologerbest: "Today's Horoscope",
        link: "/horoscope/today-horoscope"
        },
   // India's Best Astrologers end=====================


   // Call With Astrologers start=====================
  
   {
     id: 19,
     chatslidername:'chatAstrologerslider',
     image: '../images/chatastro01.jpeg',
     chatastro_name: 'Acharya Anand',
     expertisename:'Tarot, Psychologist, Nature Healing',
     expertiseprice:'240/min',
     expertisefree:'Free'

     },

     
   {
     id: 20,
     chatslidername:'chatAstrologerslider',
     image: '../images/chatastro02.jpeg',
     chatastro_name: 'Pawan Kumar',
     expertisename:'Tarot, Psychologist, Nature Healing',
     expertiseprice:'240/min',
     expertisefree:'Free'

     },

     
   {
     id: 21,
     chatslidername:'chatAstrologerslider',
     image: '../images/chatastro03.png',
     chatastro_name: 'Acharya Jasman',
     expertisename:'Tarot, Psychologist, Nature Healing',
     expertiseprice:'240/min',
     expertisefree:'Free'

     },

     
   {
     id: 22,
     chatslidername:'chatAstrologerslider',
     image: '../images/chatastro04.png',
     chatastro_name: 'Acharya Deepak',
     expertisename:'Tarot, Psychologist, Nature Healing',
     expertiseprice:'240/min',
     expertisefree:'Free'

     },

     
   // Call With Astrologers end=====================

   // Astrologers services start=====================
   {
     id: 23,
     astroservices:'astrologerServices',
     image: '../images/astroser01.png',
     astroservice_name: 'Kundli Report',
     astrodesc:'The free kundli available is a 100% free...',
     asterserreadbtn:'read more'

     },

     {
        id: 24,
        astroservices:'astrologerServices',
        image: '../images/astroser02.png',
        astroservice_name: 'Kundli Matching',
        astrodesc:'Our kundli matching for marriage is an...',
        asterserreadbtn:'read more'
  
        },

        {
           id: 25,
           astroservices:'astrologerServices',
           image: '../images/astroser03.png',
           astroservice_name: 'Horoscope',
           astrodesc:'Free predictions and guidance on matters...',
           asterserreadbtn:'read more'
     
           },


           {
              id: 26,
              astroservices:'astrologerServices',
              image: '../images/astroser04.png',
              astroservice_name: 'Daily Panchang',
              astrodesc:'Panchang" is a traditional Hindu calendar...',
              asterserreadbtn:'read more'
        
              },

              {
                 id: 27,
                 astroservices:'astrologerServices',
                 image: '../images/astroser05.png',
                 astroservice_name: 'Numerology',
                 astrodesc:'Embrace the wisdom of numerology...',
                 asterserreadbtn:'read more'
           
                 },

                 {
                    id: 28,
                    astroservices:'astrologerServices',
                    image: '../images/astroser06.png',
                    astroservice_name: 'Astrology',
                    astrodesc:'Astrology is a complex and ancient be...',
                    asterserreadbtn:'read more'
              
                    },


                    {
                       id: 29,
                       astroservices:'astrologerServices',
                       image: '../images/astroser07.png',
                       astroservice_name: 'Vedic',
                       astrodesc:'Vedic astrology, also known as Jyotis...',
                       asterserreadbtn:'read more'
                 
                       },

                       {
                          id: 30,
                          astroservices:'astrologerServices',
                          image: '../images/astroser08.png',
                          astroservice_name: 'Tarot',
                          astrodesc:'Tarot is a divination practice that u...',
                          asterserreadbtn:'read more'
                    
                          },
   // Astrologers services end=====================


    // Healing services start=====================
    {
     id: 23,
     healingservices:'Healingervices',
     image: '../images/healingser01.png',
     healingservice_name: 'Kundli Report',
     healingdesc:'The free kundli available is a 100% free...',
     healingserreadbtn:'read more'

     },

     {
        id: 24,
        healingservices:'Healingervices',
        image: '../images/healingser02.png',
        healingservice_name: 'Kundli Matching',
        healingdesc:'Our kundli matching for marriage is an...',
        healingserreadbtn:'read more'
  
        },

        {
           id: 25,
           healingservices:'Healingervices',
           image: '../images/healingser03.png',
           healingservice_name: 'Horoscope',
           healingdesc:'Free predictions and guidance on matters...',
           healingserreadbtn:'read more'
     
           },


           {
              id: 26,
              healingservices:'Healingervices',
              image: '../images/healingser04.png',
              healingservice_name: 'Daily Panchang',
              healingdesc:'Panchang" is a traditional Hindu calendar...',
              healingserreadbtn:'read more'
        
              },

              {
                 id: 27,
                 healingservices:'Healingervices',
                 image: '../images/healingser05.png',
                 healingservice_name: 'Numerology',
                 healingdesc:'Embrace the wisdom of numerology...',
                 healingserreadbtn:'read more'
           
                 },

                 {
                    id: 28,
                    healingservices:'Healingervices',
                    image: '../images/healingser06.png',
                    healingservice_name: 'healinglogy',
                    healingdesc:'healinglogy is a complex and ancient be...',
                    healingserreadbtn:'read more'
              
                    },


                    {
                       id: 29,
                       healingservices:'Healingervices',
                       image: '../images/healingser07.png',
                       healingservice_name: 'Vedic',
                       healingdesc:'Vedic healinglogy, also known as Jyotis...',
                       healingserreadbtn:'read more'
                 
                       },

                       {
                          id: 30,
                          healingservices:'Healingervices',
                          image: '../images/healingser08.png',
                          healingservice_name: 'Tarot',
                          healingdesc:'Tarot is a divination practice that u...',
                          healingserreadbtn:'read more'
                    
                          },
   // Healing services end=====================

   // easy four step start=====================

   
   // easy four step start=====================

   
   {
      id: 31,
     stepprocess: 'easystepprofour',
     image: '../images/step1.png',
     step_name: 'Login',
     stepdesc: 'Login to TalkndHeal Online Via Mobile Number.',
     stepcount: '1',
     
      },
 
 
      
    {
      id: 32,
      stepprocess:'easystepprofour',
      image: '../images/step2.png',
      step_name: 'Recharge',
      stepdesc:'Put Money In Your Wallet.',
      stepcount:'2',
      link:"/wallet",
 
      },
 
 
      
    {
      id: 33,
      stepprocess:'easystepprofour',
      image: '../images/step3.png',
      step_name: 'Call / Chat',
      stepdesc:'Then find the astrologer of your choice.',
      stepcount:'3',
      link:"/chat",
 
      },
 
      
    {
      id: 34,
      stepprocess:'easystepprofour',
      image: '../images/step4.png',
      step_name: 'Review',
      stepdesc:'Rate and Review After Consultation.',
      stepcount:'4',
      link:"",
 
      },
         // easy four step end=====================


         // talk nd slider start=====================
 
         {
           id: 35,
           talkndslider:'talkndsldname',
           image: '../images/tlknd01.png',
           talknd_title: 'Tarot',
           talknddesc:'Tarot is a divination practice that uses a deck of cards, known as tarot cards, to gain insights into past, present, and future events, as well as to explore spiritual and psychological aspects of life. While the exact origins of tarot are debated, it is believed to have originated in Europe during the late medieval period. A standard tarot deck typically...',
           talkndreadbtn:'read more'
     
           },

           {
              id: 36,
              talkndslider:'talkndsldname',
              image: '../images/tlknd02.png',
              talknd_title: 'Psychologist',
              talknddesc:'Psychology, as a discipline, explores the intricacies of human behavior, cognition, and emotion, seeking to understand the complexities of the mind and its impact on individuals&#39; lives. Rooted in empirical research and evidence-based practices, psychology encompasses various theoretical perspectives, therapeutic modalities, and techniques aimed at promoting men...',
              talkndreadbtn:'read more'
        
              },

              {
                 id: 37,
                 talkndslider:'talkndsldname',
                 image: '../images/tlknd03.png',
                 talknd_title: 'Pranayama',
                 talknddesc:'Pranayama yoga, a aspect of traditional yoga practice, focuses on breath control and regulation to enhance physical, mental, and spiritual well-being. Rooted in ancient Indian traditions and philosophies, pranayama is believed to facilitate the flow of prana (life force) throughout the body, promoting balance, vitality, and inner harmony...',
                 talkndreadbtn:'read more'
           
                 },

                 {
                    id: 38,
                    talkndslider:'talkndsldname',
                    image: '../images/tlknd04.jpeg',
                    talknd_title: 'Yoga mudras',
                    talknddesc:'Yoga mudras are hand gestures used in yoga and meditation to enhance physical, mental, and spiritual well-being. Derived from Sanskrit, &quot;mudra&quot; means &quot;gesture&quot; or &quot;seal.&quot; Each mudra has specific benefits and purposes. Chin Mudra, for instance, involves touching the index finger to the thumb and is known to enhance concentration and sti...',
                    talkndreadbtn:'read more'
              
                    },

                    {
                       id: 39,
                       talkndslider:'talkndsldname',
                       image: '../images/tlknd05.png',
                       talknd_title: 'Dietician',
                       talknddesc:'Dietetics, as a field, is dedicated to promoting health and well-being through nutrition and dietary interventions. Dieticians utilize scientific knowledge of food, nutrients, and metabolism to develop personalized nutrition plans tailored to individuals&#39; unique needs, preferences, and health goals...',
                       talkndreadbtn:'read more'
                 
                       },

                       {
                          id: 40,
                          talkndslider:'talkndsldname',
                          image: '../images/tlknd05.png',
                          talknd_title: 'Sun Rays Healing',
                          talknddesc:'Surya Rays healing, also known as solar energy healing, is a holistic practice that harnesses the healing power of the sun&#39;s rays to promote physical, emotional, and spiritual well-being. Rooted in ancient traditions and various cultural practices, Surya Ray&#39;s healing acknowledges the sun as a source of life-giving energy and vitality...',
                          talkndreadbtn:'read more'
                    
                          },
         // talk nd slider end=====================

         // testimonials slider start===================
         {
           id: 41,
           testislider:'testisldname',
           image: '../images/testiicon01.png',
           testiclient_name: 'Pawan Kumar',
           talknddesc:'Incredible insights! The astrological consultation I received was not only accurate but also profoundly insightful. The astrologer deep understanding and articulate explanations provided clarity on various aspects of my life.',
     
           },

           {
              id: 42,
              testislider:'testisldname',
              image: '../images/testiicon01.png',
              testiclient_name: 'Jasman',
              talknddesc:'Not only this app provides me with a free daily horoscope but also helps me resolve my personal and professional queries in a friendly manner. I have never seen an app that has fantastic customer service like this.',
        
              },

              {
                 id: 43,
                 testislider:'testisldname',
                 image: '../images/testiicon01.png',
                 testiclient_name: 'Shobhit',
                 talknddesc:'This app helped me to get a job in my dream company. I was stressed about not getting a career opportunity after my graduation. One prediction from an astrologer gave me a ray of hope and within a few months.',
           
                 },

                
         // testimonials slider end=====================
         
         // Verified Astrologers & Secure Payments start=====================
         {
           id: 44,
           verfiednm:'verifiesastro',
           image: '../images/ver1.png',
           verifiedti_name: 'Verified Astrologers',
           verifiedddesc:'Meticulous verification process to guarantee their expertise and credibility.',
           link:"/verifiedastrologers"

     
           },

           {
              id: 45,
              verfiednm:'verifiesastro',
              image: '../images/ver2.png',
              verifiedti_name: 'Secure Payments',
              verifiedddesc:'All transactions are safeguarded with the latest encryption technology.',
              link:"/securepyments"
        
              },

              {
                 id: 46,
                 verfiednm:'verifiesastro',
                 image: '../images/ver3.png',
                 verifiedti_name: '100% Satisfaction',
                 verifiedddesc:'Our commitment goes beyond  exceptional service to gain customer satisfaction.',
              link:"/satisfaction"

           
                 },

                 {
                    id: 47,
                    verfiednm:'verifiesastro',
                    image: '../images/ver4.png',
                    verifiedti_name: 'Privacy Assurance',
                    verifiedddesc:'We prioritize your privacy as a fundamental aspect of our commitment to you.',
                 link:"/privacyassurance"

              
                    },
         // Verified Astrologers & Secure Payments end=====================

   
   ]
   
