import React from 'react'

const LoadingUserProfile = () => {
  return (
    <>
      
     <div className='wallet_loader_main_div main_div_loader_userprofile'>
        <div className='img_loader_div'>
        <img src={require('../assets/img/loaderimg.png')} alt='loader'/>
        <div className='loader_circle'></div>
        </div>
       </div>
    </>
    
  
  )
}

export default LoadingUserProfile
