
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import './pages/style.css';
import './pages/responsive.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/Scrolltop';
import Freekundali from './pages/Freekundali';
import HoroscopeDetail from './pages/HoroscopeDetail';

import JoinAsastrologer from './pages/JoinAsastrologer';
import Samuhikpuja from './pages/Samuhikpuja';
import Samuhikpujadetails from './pages/Samuhikpujadetails';
import Wallet from './pages/Wallet';
import Payment from './pages/Payment';
import PaymentDetails from './pages/PaymentDetails';
import Userprofile from './pages/Userprofile';
import Notifications from './pages/Notifications';
import Orderhistory from './pages/Orderhistory';

import Ticket from './pages/Ticket';
import Blockedastro from './pages/Blockedastro';
import Verifiedastrologers from './pages/Verifiedastrologers';
import Refundcancellation from './pages/Refundcancellation';
import Termsandconditions from './pages/Termsandconditions';
import Privacypolicy from './pages/Privacypolicy';
import Disclaimer from './pages/Disclaimer';
import Aboutus from './pages/Aboutus';
import Contactus from './pages/Contactus';
import Feedback from './pages/Feedback';
import LalKitab from './pages/LalKitab';
import LalKitabReport from './pages/LalKitabReport';
import Numerology from './pages/Numerology';
import Numerologyreport from './pages/Numerologyreport';
import Generateticket from './pages/Generateticket';
import Panchang from './pages/Panchang';
import Ticketdetails from './pages/Ticketdetails';
import ServicesPage from './pages/Services';
import Horoscope from './pages/Horoscope';
import MonthlyHoroscopeDetails from './pages/MonthlyHoroscopeDetails';
import ChaughadiyaComponent from './pages/ChaughadiyaComponent';
import Freekundalireport from './components/KundaliReport/Freekundalireport';
import KundliMatching from './pages/KundliMatching';
import PricingPolicy from './pages/PricingPolicy';
// import AstrologerChatCard from './components/AstrologerChatCard/AstrologerChatCard';

import Sitemap from './pages/Sitemap';
import MyProvider from './Context/MyProvider';
import { useEffect } from 'react';
import { generateToken, messaging } from './firebase';
import { onMessage } from 'firebase/messaging';
import toast, { Toaster } from 'react-hot-toast';

// import AstrologerHome from './pages/AstrologerHome';
// import Live from './pages/Live';
// import LiveSession from './components/LiveSession/LiveSession';
// import AstrologersDetails from './pages/AstrologersDetails';
// import ChatAssistant from './components/LiveChat/ChatAssistant';
import Securepyments from './pages/Securepyments';
import Satisfaction from './pages/Satisfaction';
import Privacyassurance from './pages/Privacyassurance';
import PageNotFound from './pages/PageNotFound';
import Editprofile from './pages/Editprofile';
import GeneratePDFButton from './pages/GeneratePDFButton';
import KundliMatchingReport from './components/KundaliReport/KundliMatchingReport';
import PaymentComponent from './pages/PaymentComponent';
import InvoiceDownloadC from './pages/InvoiceDownload';

function App() {
  // useEffect(() => {
  //   generateToken();
  //   onMessage(messaging, (payload) => {
  //     console.log("Message received. ", payload);
  //     const { title, body, image } = payload.notification;

  //     // Define maximum length for title and body
  //     const maxLength = 50;

  //     // Truncate title and body if they exceed maximum length
  //     const truncatedTitle = title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
  //     const truncatedBody = body.length > maxLength ? `${body.slice(0, maxLength)}...` : body;

  //     toast(
  //       <div style={{ textAlign: 'center', backgroundColor: 'rgb(255, 229, 181)', border: '2px solid brown', padding: '5px', borderRadius: '5px', margin: '0', position: 'relative' }}>
  //         <strong style={{ display: 'block', marginBottom: '10px', color: '#804C35' }}>{truncatedTitle}</strong>
  //         <p style={{ marginBottom: '5px' }}>{truncatedBody}</p>
  //         {image && <img src={image} alt="Notification Image" style={{ width: '100%', maxHeight: '200px' }} />}
  //         <button 
  //           onClick={() => toast.dismiss()} 
  //           style={{ 
  //             position: 'absolute', 
  //             top: '0px', 
  //             right: '3px', 
  //             backgroundColor: 'transparent', 
  //             border: 'none', 
  //             fontSize: '18px', 
  //             cursor: 'pointer' 
  //           }}
  //         >
  //           &times;
  //         </button>
  //       </div>
  //     );
  //   });
  // }, []);

  return (
    <>
  <BrowserRouter>
<MyProvider>
  <Routes>
  {/* <Route path="/" Component={Home}/> */}
    <Route path="/" Component={Home}/>
    <Route path="/freeKundali" Component={Freekundali}/>
    {/* ebfhefhehf  */}
    <Route path="/kundliMatching" Component={KundliMatching}/>
    {/* hello  */}
    <Route path="/horoscope/:id" Component={Horoscope}/>
    <Route path="/horoscope/:id/:horosName" Component={HoroscopeDetail}/>
    <Route path="/ddd" Component={MonthlyHoroscopeDetails}/>
 
    <Route path="/services/:id" Component={ServicesPage}/>

    <Route path="*" Component={PageNotFound}/>
    <Route path="/Sitemap" Component={Sitemap}/>
    <Route path="/kundliMatchingReport" Component={KundliMatchingReport}/>

   
    

    <Route path="/JoinAsastrologer" Component={JoinAsastrologer}/>
    <Route path="/Samuhikpuja" Component={Samuhikpuja}/>
    <Route path="/Samuhikpuja/:id" Component={Samuhikpujadetails}/>
    <Route path="/wallet" Component={Wallet}/>
    <Route path="/payment" Component={Payment}/>
    <Route path="/paymentDetails" Component={PaymentDetails}/>
    <Route path="/userprofile" Component={Userprofile}/>
    <Route path="/notifications" Component={Notifications}/>
    <Route path="/orderhistory" Component={Orderhistory}/>
    <Route path="/ticket" Component={Ticket}/>
    {/* <Route path="/ticketdetails" Component={Ticketdetails}/> */}
    <Route path="/blockedastro" Component={Blockedastro}/>
      <Route path="/verifiedastrologers" Component={Verifiedastrologers}/>
    <Route path="/securepyments" Component={Securepyments}/>
    <Route path="/satisfaction" Component={Satisfaction}/>
    <Route path="/privacyassurance" Component={Privacyassurance}/>
    <Route path="/refundcancellation" Component={Refundcancellation}/>
    <Route path="/termsandconditions" Component={Termsandconditions}/>
    <Route path="/privacypolicy" Component={Privacypolicy}/>
    {/* <Route path="/pricingpolicy" Component={PricingPolicy}/> */}
    <Route path="/disclaimer" Component={Disclaimer}/>
    <Route path="/aboutus" Component={Aboutus}/>
    <Route path="/contactus" Component={Contactus}/>
    {/* <Route path="/feedback" Component={Feedback}/> */}
    <Route path="/editprofile" Component={Editprofile}/>
    <Route path="/lalKitab" Component={LalKitab}/>
    <Route path="/lalKitabReport" Component={LalKitabReport}/>
    <Route path="/numerology" Component={Numerology}/>
    <Route path="/numerologyreport" Component={Numerologyreport}/>
    <Route path="/numerologyreport/:idKundali" Component={Numerologyreport}/>
    <Route path="/kundali" Component={Freekundali}/>
    <Route path="/generateticket" Component={Generateticket}/>
    <Route path="/panchang" Component={Panchang}/>
    <Route path="/freekundalireport/:idKundali" Component={Freekundalireport}/>
    <Route path="/freekundalireport" Component={Freekundalireport}/>

    <Route path="/chaughadiyacomponent" Component={ChaughadiyaComponent}/>
    {/* <Route  path='/' Component={AstrologerHome}>
    
    <Route path='/:types/:idChy' Component={AstrologerHome}/>
    <Route path='/:types' Component={AstrologerHome} /> 
    
  </Route> */}
    
     {/* <Route path="/chatassistant" Component={ChatAssistant}/>
    <Route path="/astrologersDetails/:id" Component={AstrologersDetails}/>
    <Route path="/live" Component={Live}/>
  <Route path='/live/:types' Component={LiveSession}></Route>   */}
  <Route path='/generatepdf' Component={GeneratePDFButton}></Route>  
  <Route path='/paymentcomponent' Component={PaymentComponent}></Route>  
    <Route path='/invoiceDownload' element={<InvoiceDownloadC/>}> </Route>
    {/* efe  */}
  </Routes>
  </MyProvider>
 
  <ScrollToTop/>
  </BrowserRouter>
    </>
  );
}


export default App;
