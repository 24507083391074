import React from "react";
import { TfiFaceSad } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { Domain_Url } from "../components/Url/Url";

const PageNotFound = () => {
    return (
        <div className="container" >
            <div className="row d-flex justify-content-center justify-content-center py-4 fs-1" style={{marginTop:"30px"}} >
                <div className="col-md-5 Section_Container">
                    <div className="text-center py-3 d-flex justify-content-center">
                    <Link to="/"><img src={`${Domain_Url}/logo/logo.png`}  alt="talkndheal logo" className="img-fluid" width={300}/></Link>
                    </div>
                    <h1 className="text-center ">404</h1>
                    <p className="text-center  m-0 text-black">Page not found...</p>
                    <p className="text-center">
                        <a href="/" className="fs-5" style={{color:"#804C35" }}>
                            {" "}
                            Back to the Home
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;