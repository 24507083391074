import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Sitemap.css'; 
import Header from '../components/Header';
import Footer from '../components/Footer';import { useState } from 'react';
import LoadingPolicies from '../components/LoadingComponent/LoadingPolicies';
; 

const Sitemap = () => {
    const [loading, setloading] = useState(true);
    useEffect(() =>{
       setloading(false);
    })
    return (
        <>
        {/* header start */}
        <Header/>
        {/* header start */}

{/* sitemap section start */}
{!loading ? (<section className="sitemap_section">
    <div className="container">
        <div className="row">
            {/* col start */}
            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                <div className="main_div_sitemap_links">
                <h2>Astrology Services</h2>
                <ul>
                        <li><Link to="/freeKundali">Kundli</Link></li>
                        <li><Link to="/kundlimatching">Kundli Matching</Link></li>
                        {/* <li><Link to="/services/horoscopes">Horoscopes</Link></li> */}
                        <li><Link to="/panchang">Panchang</Link></li>
                        <li><Link to="/numerology">Numerology</Link></li>
                        <li><Link to="/services/astrology">Astrology</Link></li>
                        <li><Link to="/services/vedic">Vedic</Link></li>
                        <li><Link to="/services/tarot">Tarot</Link></li>
                    </ul>
                </div>
            </div>
            {/* col end */}

             {/* col start */}
             <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                <div className="main_div_sitemap_links">
                <h2>Healing Services</h2>
                <ul>
                        <li><Link to="/services/pranayama">Pranayama</Link></li>
                        <li><Link to="/services/yoga-mudras">Yoga Mudras</Link></li>
                        <li><Link to="/services/sun-rays-healing">Sun Rays Healing</Link></li>
                        <li><Link to="/services/sound-and-words-healing">Sound and Words Healing</Link></li>
                        <li><Link to="/services/crystal-healing">Crystal Healing</Link></li>
                        <li><Link to="/services/acupressure-healing">Acupressure Healing</Link></li>
                        <li><Link to="/services/magnet-healing">Magnet Healing</Link></li>
                        <li><Link to="/services/nature-healing">Nature Healing</Link></li>
                        {/* <li><Link to="/services/metal-healing">Metal Healing</Link></li> */}
                    </ul>
                </div>
            </div>
            {/* col end */}

            
             {/* col start */}
             {/* <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                <div className="main_div_sitemap_links">
                <h2>Consult Astrologers</h2>
                <ul>
                        <li><Link to="/chat">Chat with Astrologer</Link></li>
                        <li><Link to="/chat">Call with Astrologer</Link></li>
                        <li><Link to="/chat">Video Call with Astrologer</Link></li>
                        <li><Link to="/live">Live Stream with Astrologer</Link></li>
                    </ul>
                </div>
            </div> */}
            {/* col end */}

              
             {/* col start */}
             <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                <div className="main_div_sitemap_links">
                <h2>Horoscope</h2>
                    <ul>
                        <li><Link to="/horoscope/today-horoscope">Today's Horoscope</Link></li>
                        <li><Link to="/horoscope/tomorrow-horoscope">Tomorrow's Horoscope</Link></li>
                        <li><Link to="/horoscope/monthly-horoscope">Monthly Horoscope</Link></li>
                    </ul>
                </div>
            </div>
            {/* col end */}

               {/* col start */}
               <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                <div className="main_div_sitemap_links">
                <h2>Information</h2>
                    <ul>
                        <li><Link to="/refundcancellation">Refund & Cancellation Policy</Link></li>
                        <li><Link to="/termsandconditions">Terms & Conditions</Link></li>
                        <li><Link to="/privacypolicy">Privacy Policy</Link></li>
                        <li><Link to="/disclaimer">Disclaimer</Link></li>
                        <li><Link to="/pricingpolicy">Pricing Policy</Link></li>
                        <li><Link to="/aboutus">About Us</Link></li>
                        {/* <li><Link to="/testimonials">Testimonials</Link></li> */}
                    </ul>
                </div>
            </div>
            {/* col end */}

              {/* col start */}
              <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                <div className="main_div_sitemap_links">
                <h2>Contact Us</h2>
                    <ul>
                        <li><Link to="/feedback">Feedback</Link></li>
                        <li><Link to="/contactus">Contact Us</Link></li>
                        {/* <li><Link to="/raiseticket">Raise a Ticket</Link></li> */}
                    </ul>
                </div>
            </div>
            {/* col end */}

             {/* col start */}
             <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                <div className="main_div_sitemap_links">
                <h2>Services</h2>
                    <ul>
                        <li><Link to="/joinasastrologer">Join As An Astrologer</Link></li>
                        {/* <li><Link to="/samuhikpuja">Samuhik Puja</Link></li> */}
                        <li><Link to="/panchang">Daily Panchang</Link></li>
                    </ul>
                </div>
            </div>
            {/* col end */}


        </div>
    </div>
</section>): (<LoadingPolicies/>)}
{/* sitemap section end */}


       
        <Footer/>
        </>
    );
};

export default Sitemap;
