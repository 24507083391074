import React, { useEffect, useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Payment.css';
import { getUserDetailsApi, RechargefetchApi, walletTransactionApi } from '../AllApi/AllApi';
import { isAuthenticated, isUserId } from '../components/Url/Url';
import LoadingPolicies from '../components/LoadingComponent/LoadingPolicies';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const Payment = () => {
  //date format
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'dd, MMM yyyy | h:mm a');
    } catch (error) {
      return 'Invalid Date';
    }
  };

  //without login 
  const token = isAuthenticated; // Use `isAuthenticated` as a value
  const userId = isUserId; // Fetch the user ID
  const navigate = useNavigate();
  // Redirect if token or userId is null
  useEffect(() => {
    if (token === null || userId === null) {
      navigate("/");
    }
  }, [token, userId, navigate]);
  
  // Redirect if token or userId is null
  useEffect(() => {
    if (token === null || userId === null) {
      navigate("/");
    }
  }, [token, userId, navigate]);
  const [loginRes, setLoginRes] = useState("");
  const [allTransactionData,setAllTransactionData]=useState()
  const [loading, setLoading] = useState(true);
  
async  function fetchTransaction(data){
    const res=await walletTransactionApi(data)
    console.log(res);
    setAllTransactionData(res)
    setLoading(false);

  }
  const formatAmount = (amount) => {
    if (isNaN(amount) || amount === null || amount === undefined) {
      return '-';
    }
    return new Intl.NumberFormat("en-IN").format(amount);
  };
  
  const data={userId,token}
  useEffect(()=>{
    fetchTransaction(data)
  },[])

  
async function userDetails(){

  const decToken= isAuthenticated  
  const userId=isUserId
  const  res= await getUserDetailsApi(decToken,userId)
  setLoginRes(res)
  }
  useEffect(()=>{
  userDetails()
  },[])
  // 

  // recharge api
  const [RechargePayment, setRechargePayment] = useState();

  useEffect(()=>{
    const rechargeData = async() =>{
      try {
        const res = await RechargefetchApi(data);
        console.log(res);
          if(res?.success==true){
            setRechargePayment(res?.data);
          }
      } catch (error) {
        console.log(error);
      }
    }
    rechargeData();
  },[]);

  return (
    <>
     {/* ========= */}
 <Header/>
    {/* ====== */}
    {!loading ? (  <section className="payment_logs_section">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        {/**/}
        <div className="payment_tab_main_div">
          <ul className="nav nav-pills mb-2" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="pills-wallet-tab" data-bs-toggle="pill" data-bs-target="#pills-wallet" type="button" role="tab" aria-controls="pills-wallet" aria-selected="true">Wallet Transactions</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-paylog-tab" data-bs-toggle="pill" data-bs-target="#pills-paylog" type="button" role="tab" aria-controls="pills-paylog" aria-selected="false">Payment Logs</button>
            </li>
          </ul>
          {/**/}
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-wallet" role="tabpanel" aria-labelledby="pills-wallet-tab">
              <div className="payment_log_table">
                <h3 style={{fontSize: 20, color: '#df2f2d'}}>Available balance: ₹  {formatAmount(loginRes?.data?.wallet)}<a href="/wallet" style={{margin: '0px 10px', border: '1px solid #df2f2d', padding: '6px 20px 6px 20px', borderRadius: 100, fontSize: 17, backgroundColor: '#df2f2d', color: '#fff'}}>Recharge</a></h3>
                <div className="payment_log_table_scroll">
                  <table className="table table-bordered table-striped table-sm mt-3" style={{textAlign: 'center'}}>
                    <tbody>
                    <tr>
                        <th colSpan={2}>Description	</th> 
                        <th>Transaction Amount	</th>
                        <th>Date & time	</th>
                        {/*<th>Actions</th>*/}
                      </tr>
                     {allTransactionData?.data?.map((ele)=> <tr>
                      <th colSpan={2}>{ele?.payment?.replace('Cashback', 'Extra Amount')}</th> 
                        <th>{ele?.amount}	/-</th>
                        <th>{ele?.date ? formatDate(ele.date) : 'N/A'}</th>
                        {/*<th>Actions</th>*/}
                      </tr>)}
                     
                    </tbody></table>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-paylog" role="tabpanel" aria-labelledby="pills-paylog-tab">
              <div className="payment_log_table">
                <div className="btn_flx_payment">
                  <a href="/wallet" className="rch_div">Recharge</a>
                  {/* <a href="payment.php?userid=MzY=" onclick="return confirm('Are you sure you want to delete all transaction?');" className="dlt_div">Delete All</a> */}
                </div>
                <div className="payment_log_table_scroll">
                <table className="table table-bordered table-striped table-sm">
                    <tbody>
                      <tr>
                      
                        <th>Date & Time</th> 
                        <th>Amount</th>
                        <th>GST</th>
                        <th>order Id</th>
                        <th>Status</th>
                        {/* <th>Actions</th> */}
                      </tr>
                    
                    {RechargePayment?.map((ele, index)=>{
                      return(
                        <>
                        <tr>
                         
                          <td>{ele?.date_time ? formatDate(ele.date_time) : 'N/A'}</td>
                          <td>{ele?.amount} /-</td>
                          <td>{ele?.gst} /-</td>
                          <td>{ele?.orderid}</td>
                        

                          <td className={ele?.status === "Pending" ? "status-pending" : "status-success"}>
                            {ele?.status === "Pending" ? "Pending" : "Success"}
                          </td>
                        </tr>
                        </>
                      )
                    })}

                    </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>
          {/**/}
        </div>
        {/**/}
      </div>
    </div>
  </div>
</section>): (<LoadingPolicies/>)}
    
<Footer/>
    </>
  )
}

export default Payment
