

// Best India Astro kundli slider option start
export const options = {
    autoplayHoverPause:true,
    loop:true,
    margin: 3,
    responsiveClass: true,
    nav: false,
    dots:false,
    autoplay: true,
    autoplayTimeout: 2000, // 2 seconds
    responsive: {
    0: {
    items: 5,
    nav: false
    },
    600: {
    items: 5,
    nav: false
    },
    1000: {
    items: 5,
    nav: false,
    loop: true
    }
    },
    };
    // Best India Astro kundli slider option end
    
    
    // Best India Astro kundli slider option start
    export const Chatastrologer = {
      autoplayHoverPause:true,
      loop:true,
      margin: 5,
      responsiveClass: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 2000, // 2 seconds
      responsive: {
      0: {
      items: 2,
      nav: false
      },
      600: {
      items: 2,
      nav: false
      },
      1000: {
      items: 3,
      nav: false,
      loop: true
      }
      },
      };
      // Best India Astro kundli slider option end

      
    // Best India Astro kundli slider option start
    export const Chatastrologerdetails = {
      autoplayHoverPause:true,
      loop:true,
      margin: 5,
      responsiveClass: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 2000, // 2 seconds
      responsive: {
      0: {
      items: 2,
      nav: false
      },
      600: {
      items: 2,
      nav: false
      },
      1000: {
      items: 4,
      nav: false,
      loop: true
      }
      },
      };
      // Best India Astro kundli slider option end
    
      
    // talk nd slider slider option start
    export const talkndslider = {
      autoplayHoverPause:true,
      loop:true,
      margin: 5,
      responsiveClass: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 2000, // 2 seconds
      responsive: {
      0: {
      items: 1,
      nav: false
      },
      600: {
      items: 1,
      nav: false
      },
      1000: {
      items: 1,
      nav: false,
      loop: true
      }
      },
      };
      // talk nd slider slider option end
      
      
    // testimonials slider slider option start
    export const testimonialsld = {
      autoplayHoverPause:true,
      loop:true,
      margin: 5,
      responsiveClass: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 2000, // 2 seconds
      responsive: {
      0: {
      items: 1,
      nav: false
      },
      600: {
      items: 1,
      nav: false
      },
      1000: {
      items: 3,
      nav: false,
      loop: true
      }
      },
      };
      // testimonials slider option end
    
      
    // testimonials slider slider option start
    export const sliderrigtside = {
      autoplayHoverPause:true,
      loop:true,
      margin: 5,
      responsiveClass: true,
      nav: false,
      autoplay: true,
      animateOut: 'fadeOut',
      autoplayTimeout: 2000, // 2 seconds
      responsive: {
      0: {
      items: 1,
      nav: false
      },
      600: {
      items: 1,
      nav: false
      },
      1000: {
      items: 1,
      nav: false,
      loop: true
      }
      },
      };
      // testimonials slider option end

      
    // complimentaryastrology slider slider option start
    export const complimentaryastrology = {
      autoplayHoverPause:true,
      loop:true,
      margin: 5,
      responsiveClass: true,
      nav: false,
      autoplay: true,
      animateOut: 'fadeOut',
      autoplayTimeout: 2000, // 2 seconds
      responsive: {
      0: {
      items: 1,
      nav: false
      },
      600: {
      items: 1,
      nav: false
      },
      1000: {
      items: 4,
      nav: false,
      loop: true
      }
      },
      };
      // complimentaryastrology slider option end


      
    // todayhoroscopecarousel slider slider option start
    export const todayhoroscopecarousel = {
      autoplayHoverPause:true,
      loop:true,
      margin: 5,
      responsiveClass: true,
      nav: false,
      autoplay: true,
      animateOut: 'fadeOut',
      autoplayTimeout: 2000, // 2 seconds
      responsive: {
      0: {
      items: 3,
      nav: false
      },
      600: {
      items: 3,
      nav: false
      },
      1000: {
      items: 6,
      nav: false,
      loop: true
      }
      },
      };
      // todayhoroscopecarousel slider option end

      
    // similar topics slider option start
    export const Similartopicslider = {
      autoplayHoverPause:true,
      loop:true,
      margin: 5,
      responsiveClass: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 2000, // 2 seconds
      responsive: {
      0: {
      items: 2,
      nav: false
      },
      600: {
      items: 2,
      nav: false
      },
      1000: {
      items: 4,
      nav: false,
      loop: true
      }
      },
      };
      // similar topics slider option end


      
    // similar astrologer slider option start
    export const Similarastrologerdetails = {
      autoplayHoverPause:true,
      loop:true,
      margin: 5,
      responsiveClass: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 2000, // 2 seconds
      responsive: {
      0: {
      items: 1,
      nav: false
      },
      600: {
      items: 1,
      nav: false
      },
      1000: {
      items: 4,
      nav: false,
      loop: true
      }
      },
      };
      // similar astrologer slider option end
      
      
    
//  slider option start
export const HeroSliderOptions = {
  autoplayHoverPause:true,
  loop:true,
  margin: 0,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 2500, // 2 seconds
  responsive: {
  0: {
  items: 1,
  nav: false
  },
  600: {
  items: 1,
  nav: false
  },
  1000: {
  items: 1,
  nav: false,
  loop: true
  }
  },
  };
  //  slider option end