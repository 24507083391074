import React, { useContext, useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { SliderComponentsData } from '../data/SliderComponentsData';
import { Link } from 'react-router-dom';
// import { fetchastrocallData, fetchastrochatData, fetchvideoData, sliderApi } from '../data/AllapiData';
// import { options, Chatastrologer, talkndslider, testimonialsld, sliderrigtside } from '../data/Owlcarouseloptions';
import { options, Chatastrologer, talkndslider, testimonialsld, sliderrigtside, HeroSliderOptions } from '../data/Owlcarouseloptions';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { allServicesApi, astroserviceApi, getAllAstrologerChatApi, getUserDetailsApi, HealingserviceApi, SliderHerobanner, SliderHerobannerMobile, TestimonialsApi, subscribeNewsletterApi } from '../AllApi/AllApi';
// import MyContext from '../Context/MyContext';
import { decryptData, Domain_Url, isAuthenticated, isUserId, removeHtmlTags, truncatecallText } from '../components/Url/Url';
// import LoadingComponent from '../components/LoadingComponent/LoadingComponent';
// import ReloadModal from '../data/ReloadModal';
// import LoadingForChat from '../components/LoadingComponent/LoadingForChat';
import LoadingForService from '../components/LoadingComponent/LoadingForService';
import LoadingForHeroslider from '../components/LoadingComponent/LoadingForHeroslider';
import LoadingForMobileslider from '../components/LoadingComponent/LoadingForMobileslider';
import LoadingAstrologyService from '../components/LoadingComponent/LoadingAstrologyService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import img1 from '../images/testiicon01.png';
import img1 from '../components/assets/img/testiicon01.png'



const Home = () => {

// Heroslider  api start------------------1-----------------------------------------
const [SliderHeroData, setSliderHeroData] = useState();
const [SliderHeroDataUrl, setSliderHerorl] = useState();
const [SliderPreloader, setSliderPreloader] = useState(true);
const [isSliderloaded, setSliderloaded]=useState(false);
useEffect(() =>{
  const SliderHero =  async () =>{
    try {
      const res = await SliderHerobanner ();
  
      if(res?.success===true){
        setSliderPreloader(false)
        setSliderHeroData(res?.data);
        setSliderHerorl(res?.url);
        setSliderloaded(true);//use flag to set api loaded
      }
    } catch (error) {
      console.log(error);
    }
  }
   
  if (window.matchMedia("(max-width: 767px)").matches) {
    // SliderHeroMobile();
   }
   else{
     SliderHero();
   }
 },[])
 // Heroslider  api end--------------------------------------------------------------
 
   // mob Heroslider API start------------------------------------------------------------
   const [SliderHeroMobileData, setSliderHeroMobileData] = useState();
   const [SliderHeroMobileDataUrl, setSliderHeroMobilerl] = useState();
   const [SliderMobilePreloader, setSliderMobilePreloader] = useState(true);
   const [ismobSliderloaded, setmobSliderloaded]= useState(false);
 
   useEffect(() => {
     const SliderHeroMobile = async () => {
       try {
         const res = await SliderHerobannerMobile();
 
         if (res?.success === true) {
           setSliderMobilePreloader(false);
           setSliderHeroMobileData(res?.data);
           setSliderHeroMobilerl(res?.url);
           setmobSliderloaded(true);
         }
       } catch (error) {
         console.log(error);
       }
     };
 
     const handleResize = () => {
       if (window.matchMedia("(max-width: 767px)").matches) {
         SliderHeroMobile();
       }
     };
 
     // Initial check
     handleResize();
 
     // Add event listener for resize
     window.addEventListener('resize', handleResize);
 
     // Cleanup event listener on component unmount
     return () => {
       window.removeEventListener('resize', handleResize);
     };
   }, []);
   // mobile API end-------------------------------------------------------------------

  // astrologyservice  api start------------------2-----------------------------------
const [astrologyserviceData, setastrologyserviceData] = useState();
const [astrologyserviceDataUrl, setastrologyserviceurl] = useState();
const [astrologyserviceDataLoading, setastrologyserviceLoading] = useState(true);
const [isastrologyserviceloaded, setastrologyserviceloaded]= useState(false);
useEffect(() =>{
  const astrologyserviceDataslider =  async () =>{
    try {
      const res = await astroserviceApi ();
      if(res?.success==true){
        setastrologyserviceLoading(false);
        setastrologyserviceData(res?.data);
        setastrologyserviceurl(res?.url);
        setastrologyserviceloaded(true);//use flag to set api loaded
      }
    } catch (error) {
      console.log(error);
    }
  }
  astrologyserviceDataslider();
},[])
// astrologyservice  api end-------------------------------------------------------------

// Healingservice  api start---------------------3----------------------------------------------
const [HealingserviceData, setHealingserviceData] = useState();
const [HealingserviceDataUrl, setHealingserviceurl] = useState();
const [HealingserviceLoading, setHealingserviceLoading] = useState(true);
useEffect(() =>{
  if(isastrologyserviceloaded && (isSliderloaded || ismobSliderloaded)){
  const HealingserviceDataslider =  async () =>{
    try {
      const res = await HealingserviceApi ();
      if(res?.success===true){
        setHealingserviceLoading(false);
        setHealingserviceData(res?.data);
        setHealingserviceurl(res?.url);
      }
    } catch (error) {
      console.log(error);
    }
  }
  HealingserviceDataslider();
}
},[isastrologyserviceloaded,isSliderloaded, ismobSliderloaded])
// Healingservice  api end--------------------------------------------------------
 
// astrologer services api start---------4-----fetch expertise--------------------------
const [astroserviceData, setastroserviceData] = useState();
const [astroserviceDataUrl, setastroserviceDataUrl] = useState();
useEffect(() =>{
  if(isastrologyserviceloaded && (isSliderloaded || ismobSliderloaded)){
  const serviceAstrodata =  async () =>{
    try {
      const res = await allServicesApi ();
  
      if(res?.success===true){
        setLoadingForService(false)
        setastroserviceData(res?.data);
        setastroserviceDataUrl(res?.url);
      }
    } catch (error) {
      console.log(error);
    }
  }
  serviceAstrodata();
}
},[isastrologyserviceloaded,isSliderloaded,ismobSliderloaded])
// astrologer services api end-------------------------------------------------

// Testimonials  api start------------------------5--------------------------------
const [TestimonialsData, setTestimonialsData] = useState();
const [TestimonialsDataUrl, setTestimonialsurl] = useState();
useEffect(() =>{
  if(isastrologyserviceloaded){
  const TestimonialsDataslider =  async () =>{
    try {
      const res = await TestimonialsApi ();
      if(res?.success===true){
        setTestimonialsData(res?.data);
        setTestimonialsurl(res?.url);
      }
    } catch (error) {
      console.log(error);
    }
  }
  TestimonialsDataslider();
}
},[isastrologyserviceloaded])
// Testimonials  api end-------------------5-----------------------------------------

//newsletter starts--------------------------------------------------------
const [email, setEmail] = useState('');
const [status, setStatus] = useState(null);

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

const handleSubmit = async (event) => {
  event.preventDefault();

  if (!validateEmail(email)) {
    toast.error('Invalid email address');
    return;
  }

  try {
    const response = await subscribeNewsletterApi({
      email: email,
      status: '1', // Setting status to '1' by default
    });

    console.log('API Response:', response); // Log the API response

    if (response.error) {
      throw new Error(response.error);
    }

    setStatus(response.status);
    setEmail(''); // Clear the input field
    toast.success('Subscribed successfully!');
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    toast.error('Failed to subscribe. Please try again.');
  }
};
//newsletter ends--------------------------------------------------------

// user Validation --------------------------------------------------------
const [loading, setLoading] = useState(true);
const [loadingForCall, setLoadingForCall] = useState(true);
const [loadingForVideo, setLoadingForVideo] = useState(true);
const [loadingForService, setLoadingForService] = useState(true);
// const [loginRes, setLoginRes] = useState("");
const formatAmount = (amount) => {
  return new Intl.NumberFormat("en-IN").format(amount);
  };

async function userDetails(){

  const decToken= isAuthenticated
  const userId=isUserId
  const  res= await getUserDetailsApi(decToken,userId)
  // setLoginRes(res)
  
  }

  useEffect(()=>{
  userDetails()
  },[])


  // const [slider,setSlider]=useState()


  // async function fetchSlider(){
  //    const res= await sliderApi()
  //    if(res?.success==true){
  //      setSlider(res?.data)
  //    }
  //  }
  //  useEffect(()=>{
  //    fetchSlider()
  //  },[])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if(chatdata!=null && calldata!=null && videoData !=null)
  //     {setLoading(false);}
  //   }, 1000); 

  //   return () => clearTimeout(timer);
  // }, []);


  const renderStars = (rating) => {
    const stars = [];
    // Determine the number of full and half stars
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    
    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<i key={i} className="fa fa-star fontCard"></i>);
      } else if (i === fullStars + 1 && halfStar) {
        stars.push(<i key={i} className="fa fa-star-half-alt fontCard"></i>);
      } else {
        stars.push(<i key={i} className="fa fa-star-o fontCard"></i>); // empty star
      }
    }
    return stars;
  };

  // end chat api--------------------------------------------------------


// Kundli slider========================
const filterKundlislider = SliderComponentsData.filter(item => item.slidername == 'bestindiakundli');
// Desktop slider========================
// Mobile slider========================
// const filterheroslidermobile = SliderComponentsData.filter(item => item.slidername == 'heroslidermobile');
// Astrologer india best========================
const filterheroAstrologerbset = SliderComponentsData.filter(item => item.bestindiaAstroname == 'bestindiaAstrologer');

//stpe easy ========================
const filterstepeasy = SliderComponentsData.filter(item => item.stepprocess == 'easystepprofour');


//VERIFIED ASTRO  ========================
const verifiedfilter = SliderComponentsData.filter(item => item.verfiednm == 'verifiesastro');

return (
<>
   {/* ========= */}
 <Header/>
    {/* ====== */}
{/* wrapper start */}
<div className="main_wrapper">
{/* container start */}
<div className="container">
{/* row start */}
<div className="row">
{/* ====================col start left side 9 col====================  */}
<div className="col-lg-9 col-md-9 col-sm-12 col-12">
{/* ==================India's Best Astrologers slider mobile view section start============ */}
<section className="kundli_astro_mobile_slider_sec">
<div className="kundli_astro_main_div_mobile_sld">
<OwlCarousel className="owl-theme" {...options}>
{/* item div start */}
{ filterKundlislider.map((item, index) => (
<div className="item" key={index}>
<Link to={item.link}>
<div className="kundli_astrokundli_main_div">
<div className="kundli_astro_img_box">
<img src={item.image} alt={item.kundliname} className='img-fluid'/>
</div>
<h4>{item.kundliname}</h4>
</div>
</Link>
</div>
)) }
{/* item div end */}

</OwlCarousel>
</div>
</section>
{/* ==================India's Best Astrologers slider mobile view  section start============ */}

{/* ============================== hero slider desktop sectin start ====================================== */}
<section className="heroslider_section_desktop">
  {!SliderPreloader ? (
  <OwlCarousel  className="owl-theme" {...HeroSliderOptions}>
  {SliderHeroData?.map((item, index) => (
    <div className="item" key={index}>
      <Link to={item?.url}>
        <img src={`${SliderHeroDataUrl}${item?.image}`} className="d-block w-100" alt="Slider" />
      </Link>
    </div>
  ))}
</OwlCarousel>
  ) : (
    <LoadingForHeroslider />
  )}
</section>


{/* ============================== hero slider desktop section end ====================================== */}

{/* ============================== mobile slider mobile section start ====================================== */}
<section className="heroslider_section_mobile">
{!SliderMobilePreloader ? (
    <OwlCarousel  className="owl-theme" {...HeroSliderOptions}>
      {SliderHeroMobileData?.map((item, index) => (
        <div className="item" key={index}>
          <Link to={item?.url}>
            <img src={`${SliderHeroMobileDataUrl}${item?.image}`} className="d-block w-100" alt="Slider" />
          </Link>
        </div>
      ))}
       </OwlCarousel>
  ) : (
    <LoadingForMobileslider />
  )}
</section>

{/* ============================== mobile slider mobile sectin end ====================================== */}

{/* ============================== India's Best Astrologers sectin start ====================================== */}
<section className="india_best_astrologer_section">
  <div className="container">
    {/* india_astrologer title start  */}
    <div className="india_bestastrologer_title">
      <h1>India's Best Astrologers</h1>
    </div>
    {/* india_astrologer title end */}
    <div className="row">
    {/* col start */}

{ filterheroAstrologerbset.map((item, index) => {
  return(
    <div className="col-lg-2 col-md-4 col-sm-6 col-6" key={index}>
    <Link to={item?.link}>
    <div className="india_bestastrologer_main_div">
      <div className="india_best_astrologer_imgbox">
      <img src={item?.image} alt={item?.astrologerbest} className='img-fluid'/>
      </div>
      <strong>{item?.astrologerbest}</strong>
    </div>
    </Link>
  </div>
  )
}) }
    {/* col end */}
    </div>
  </div>
</section>
{/* ============================== India's Best Astrologers sectin end ====================================== */}


{/* ====================  Astrologers services section end ============ */}
<section className="astrologer_services_section">
  {/* Healing container start */}
  <div className="container">
    {/* Healing service title */}
    <div class="astrologer_services_title">
        <h4>Astrology Services</h4>
    </div>
    {/*  */}
    {!astrologyserviceDataLoading ?  (<div className="row">
      
{/* col start */}
<div className="col-lg-3 col-md-6 col-sm-4 col-4 mobile_view_astroservice">
      <Link to="/freeKundali">
        <div className="astroservice_main_div">
          <div className="astroservice_img_box">
            <img src="../images/ser1.png" alt="Kundli Report" className="img-fluid" />
          </div>
          <h5>Kundli Report</h5>
          <p>The free kundli available is a 100% free...  <span> Read More</span></p>
        </div>
      </Link>
    </div>
      {/* col end */}

       {/* col start */}
       <div className="col-lg-3 col-md-6 col-sm-4 col-4 mobile_view_astroservice" >
      <Link to="/kundliMatching">
        <div className="astroservice_main_div">
          <div className="astroservice_img_box">
            <img src="../images/ser2.png" alt="Kundli Matching" className="img-fluid" />
          </div>
          <h5>Kundli Matching</h5>
          <p>Our kundli matching for marriage is an...  <span> Read More</span></p>
        </div>
      </Link>
    </div>
      {/* col end */}


       {/* col start */}
       <div className="col-lg-3 col-md-6 col-sm-4 col-4 mobile_view_astroservice" >
      <Link to="/horoscope/today-horoscope">
        <div className="astroservice_main_div">
          <div className="astroservice_img_box">
            <img src="../images/ser3.png" alt="Horoscope" className="img-fluid" />
          </div>
          <h5>Horoscope</h5>
          <p>Free predictions and guidance on matters... <span> Read More</span></p>
        </div>
      </Link>
    </div>
      {/* col end */}


       {/* col start */}
       <div className="col-lg-3 col-md-6 col-sm-4 col-4 mobile_view_astroservice" >
      <Link to="/panchang">
        <div className="astroservice_main_div">
          <div className="astroservice_img_box">
            <img src="../images/astroser04.png" alt="Daily Panchang" className="img-fluid" />
          </div>
          <h5>Daily Panchang</h5>
          <p>"Panchang" is a traditional Hindu calendar...  <span> Read More</span></p>
        </div>
      </Link>
    </div>
      {/* col end */}


       {/* col start */}
       <div className="col-lg-3 col-md-6 col-sm-4 col-4 mobile_view_astroservice">
      <Link to="/numerology">
        <div className="astroservice_main_div">
          <div className="astroservice_img_box">
            <img src="../images/astroser05.png" alt="Numerology" className="img-fluid" />
          </div>
          <h5>Numerology</h5>
          <p>Embrace the wisdom of numerology...  <span> Read More</span></p>
        </div>
      </Link>
    </div>
      {/* col end */}


{/* col start  */}

{astrologyserviceData?.map((item, index) => {
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  return (
    <div className="col-lg-3 col-md-6 col-sm-4 col-4" key={index}>
      <Link to={`/services/${item?.name.replace(/\s+/g, '-')}`}>
        <div className="astroservice_main_div">
          <div className="astroservice_img_box">
            <img src={`${astrologyserviceDataUrl}${item?.icon}`} alt={item?.name} className="img-fluid" />
          </div>
          <h5>{item?.name}</h5>
          <p>{truncateText(removeHtmlTags(item?.description), 70)} <span>Read More</span></p>
        </div>
      </Link>
    </div>
  );
})}


{/* col end */}
    </div>) : (<LoadingAstrologyService/>)}
  </div>
  {/* astro container end */}

  {/* Healing container start */}
  <div className="container">
    {/* Healing service title */}
    <div class="astrologer_services_title">
        <h4>Healing Services</h4>
    </div>
    {/*  */}
    {!HealingserviceLoading ? (<div className="row">
{/* col start  */}

{HealingserviceData?.map((item, index) =>{
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };
 
  return(
<>
<div className="col-lg-3 col-md-6 col-sm-4 col-4">
  <Link to={`/services/${item?.name.replace(/\s+/g, '-')}`}>
  <div className="astroservice_main_div" key={index}>
    <div className="astroservice_img_box">
      <img src={`${HealingserviceDataUrl}${item?.icon}`} alt={item?.name} className='img-fluid'/>
    </div>
    <h5>{item?.name}</h5>
    <p>{truncateText(removeHtmlTags(item?.description), 70)} <span>Read More</span></p>
  </div>
  </Link>
</div>
</>
  )
})}

{/* col end */}
    </div>) : (<LoadingAstrologyService/>)}
  </div>
  {/* Healing container end */}


</section>
{/* ==============================  Astrologers services section end ====================================== */}

{/* middle banner div start */}
<section className='middle_banner_section'>
 <Link to="/kundliMatching"> <img src="../banner/mdlbn.png" alt="banner middle" className='img-fluid'/></Link>
</section>
{/* middle banner div end */}

{/*===========Easy 4 Steps Process start ==============================  */}
<section className="easy_four_section">
  <div className="container">
    {/*  */}
    <div class="four_step_title">
      <h5>Easy 4 Steps Process</h5>
  </div>
    {/*  */}
    <div className="row">
     {/* col start  */}
     <div className="col-lg-12 col-md-12 col-sm-12 col-12">
     <div className="four_stp_flx_main_div">


  {/*  */}
  {filterstepeasy?.map((item, index) =>{
    return(
      <div className="stp_box_m">
         <Link to={item?.link}>
    <div className="content_stp">
      <div className="icon_stp">
        <img src={item?.image} alt={item.step_name} />
      </div>
      <h5>{item.step_name}</h5>
      <p>{item.stepdesc}</p>
      <div className="step_over">
        <b>{item?.stepcount}</b>
      </div> 
      <div className="step_over2">
        <b>{item?.stepcount}</b>
      </div>
    </div>
    </Link>
  </div> 
    )
  })}
  
  {/*  */}
</div>

     </div>
     {/* col end  */}
    </div>
  </div>
</section>

{/*===========Easy 4 Steps Process end ==============================  */}

{/*===========Easy 4 Steps Process end ==============================  */}
<section className="talkndheal_section">
  <div className="container">
    {/* talknd heal title */}
    <div className="talknd_heal_title">
    <h5>TalkndHeal</h5>
    <p>Talkndheal is platform where you can find the real solution of your all kind problems thru to give real knowledge about problem with solution, it’s a platform of knowledgeable team of 2500 experts.</p>
    </div>
    {/* talknd heal title */}
  </div>
  {/* row start  */}
  {!loadingForService?<div className="row">
    {/* col start */}
    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
       <div className="talknd_slidermain_div">
       <OwlCarousel className="owl-theme" {...talkndslider}>
{/* item div start */}

{astroserviceData?.map((item, index) =>{
   const truncateText = (text, maxLength) => {
    return text?.length > maxLength ? text?.slice(0, maxLength) + "..." : text;
  };
  return(
    
<div className="item" key={index}>
  {/* item row start */}
  <div className="row">
    {/* item col start  */}
    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
      <div className="talknd_heal_img_div_left">
        <img src={`${astroserviceDataUrl}${item?.image}`} alt={item?.name} className='img-fluid'/>
      </div>
    </div>
    {/* item col end */}

    {/* item col start  */}
    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
      <div className="tlknd_txt_div_right">
        <h5>{item?.name}</h5>
      <p>{truncateText (removeHtmlTags(item?.description),640)}  <Link to={`/services/${item?.name.replace(/\s+/g, '-')}`} class="readMore">Read More</Link></p>
      
      </div>
    </div>
    {/* item col end */}
  </div>
  {/* item row end */}
</div>
  )
})}

{/* item div end */}

</OwlCarousel>
       </div>
    </div>
    {/* col end */}
  </div>:<LoadingForService/>}
  {/* row end */}
</section>
{/*===========Easy 4 Steps Process end ==============================  */}

{/*===========testimonials section start ==============================  */}
<section className="testimonial_section">
  <div className="container">
    {/* testimonial title */}
 <div className="testimonilas_title">
  <h5>Testimonials</h5>
 </div>
    {/* end  */}
    {/* row start */}
    {!loadingForService?<div className="row">
      {/* col start */}
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <div className="testimonials_slider_maindiv">
      <OwlCarousel className="owl-theme" {...testimonialsld}>
{/* item div start */}

{TestimonialsData?.map((item, index) =>{
  return(

<div className="item" key={index}>
  <div className="testimonials_txtitem_main_box">
   <div className="testimonials_img_b">
    <img src={img1} alt={item?.name} className='img-fluid'/>
   </div>
   <h5>{item?.name}</h5>
   <p>{item?.message}</p>
  </div>
  {/* overlay quote */}
  <div class="fa fa-quote-right overlay_quote"></div>
  {/* end */}
</div>
  )
})}

{/* item div end */}

</OwlCarousel>
      </div>
      </div>
      {/* col end */}
    </div>:<LoadingForService/>}
    {/* row end */}
  </div>
</section>
{/*===========testimonials section end ==============================  */}

{/*===========Find Best Astrologers / Experts section start ===========  */}
<section className="experts_atro_sec">
  <div className="container-fluid">
    <div className="experts_title d-none">
      <h5>Find Best Astrologers / Experts</h5>
    </div>
    <div className="row">
      {/* col start */}
      <div className="col-lg-4 col-md-6 col-sm-12 col-12 d-none">
        <div className="experts_astro_main_div">
          <h5> <img src="../images/001.png" alt="Consult Astrologers" className="img-fluid" /> Consult Astrologers</h5>
          <div className="experts_list_div" style={{height: 200}}>
            <ul>
              <li><Link to="/chat">Chat with Astrologer</Link></li>
              <li><Link to="/call">Call with Astrologer</Link></li>
              <li><Link to="/video">Video call with Astrologers</Link></li>
              <li><Link to="/live">Live with Astrologers</Link></li>
            </ul>
          </div>
        </div>
      </div>
      {/* col end */}
      {/* col start */}
      <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        <div className="experts_astro_main_div">
          <h5> <img src="../images/004.png" alt="Consult Experts" className="img-fluid" />Consult Experts</h5>
          <div className="experts_list_div">
            <ul>
              <li><Link to="/services/Tarot">Tarot</Link></li>
              <li><Link to="/services/Psychologist">Psychologist</Link></li>
              <li><Link to="/services/Pranayama">Pranayama</Link></li>
              <li><Link to="/services/Yoga-mudras">Yoga mudras</Link></li>
              <li><Link to="/services/Dietician">Dietician</Link></li>
              <li><Link to="/services/Sun-Rays-Healing">Sun Rays Healing</Link></li>
              <li><Link to="/services/Sound-and-Words-Healing">Sound and Words Healing</Link></li>
              <li><Link to="/services/Vastu">Vastu</Link></li>
              <li><Link to="/services/Magnet-Healing">Magnet Healing</Link></li>
              <li><Link to="/services/Nature-Healing">Nature Healing</Link></li>
              <li><Link to="/services/Palmistry">Palmistry</Link></li>
              <li><Link to="/services/Acupressure-Healing">Acupressure Healing</Link></li>
              <li><Link to="/services/Metal-Healing">Metal Healing</Link></li>
              <li><Link to="/services/Crystal-Healing">Crystal Healing</Link></li>
              <li><Link to="/services/KP-System">KP System</Link></li>
              <li><Link to="/services/lal-Kitab">Lal Kitab</Link></li>
              <li><Link to="/services/Vedic">Vedic</Link></li>
              <li><Link to="/services/Astrology">Astrology</Link></li>
            </ul>
          </div>
        </div>
      </div>
      {/* col end */}
      {/* col start */}
      <div className="col-lg-4 col-md-6 col-sm-12 col-12 d-none">
        <div className="experts_astro_main_div">
          <h5> <img src="../images/002.png" alt="Languages" className="img-fluid" /> Languages</h5>
          <div className="experts_list_div">
            <ul>
              <li><Link to="/chat/Assamese">Astrologers in Assamese</Link></li>
              <li><Link to="/chat/Bengali">Astrologers in Bengali</Link></li>
              <li><Link to="/chat/English">Astrologers in English</Link></li>
              <li><Link to="/chat/Gujarati">Astrologers in Gujarati</Link></li>
              <li><Link to="/chat/Hindi">Astrologers in Hindi</Link></li>
              <li><Link to="/chat/Kannada">Astrologers in Kannada</Link></li>
              <li><Link to="/chat/Kashmiri">Astrologers in Kashmiri</Link></li>
              <li><Link to="/chat/Malayalam">Astrologers in Malayalam</Link></li>
              <li><Link to="/chat/Marathi">Astrologers in Marathi</Link></li>
              <li><Link to="/chat/Marwadi">Astrologers in Marwadi</Link></li>
              <li><Link to="/chat/Odia">Astrologers in Odia</Link></li>
              <li><Link to="/chat/Punjabi">Astrologers in Punjabi</Link></li>
              <li><Link to="/chat/Tamil">Astrologers in Tamil</Link></li>
              <li><Link to="/chat/Telugu">Astrologers in Telugu</Link></li>
            </ul>
          </div>
        </div>
      </div>
     
    </div>
  </div>
</section>

{/*===========Find Best Astrologers / Experts section end ============  */}

</div>
{/* ===================col end left side 9 col========================  */}

{/* ===================col start right side 3 col========================  */}
<div className="col-lg-3 col-md-3 col-sm-12 col-12">
  <div className="right_side_main_div_sticky">

    {/* slider start */}
    <div class="sidebar_main_div_sld">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>


{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/horoscope/today-horoscope"><img src="../../images/sld03.png" alt="astrologer"/></Link> 
    </div> 
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/panchang"><img src="../../images/sld04.png" alt="astrologer"/></Link> 
    </div> 
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/kundliMatching"><img src="../../images/sld05.png" alt="astrologer"/></Link> 
    </div> 
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/services/vedic"><img src="../../images/sld06.png" alt="astrologer"/></Link> 
    </div> 
</div>
{/* item div end */}


</OwlCarousel>
</div>
  {/* slider end */}

  {/* slider start */}
  <div class="sidebar_main_div_sld2">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>
{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/horoscope/today-horoscope"><img src="../images/small01.png" alt="today horoscope"/></Link> 
    </div> 
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/services/vedic"><img src="../images/small02.png" alt="vedic"/></Link> 
    </div> 
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/kundliMatching"><img src="../images/small03.png" alt="kundlimatching"/></Link> 
    </div> 
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/panchang"><img src="../images/small04.png" alt="panchang"/></Link> 
    </div> 
</div>
{/* item div end */}
</OwlCarousel>
</div>
  {/* slider end */}

  
  {/* app div */}
  <div className="app_download">
    <h2>TalkndHeal on Mobile</h2>
    <a href>Download Now</a>
  </div>
  {/*  */}
  {/* left icons start */}
  <div className="left_icons_so">
  <Link target="_blank" to="https://www.facebook.com/talkndhealofficial/"><img src={`${Domain_Url}/images/fb.png`} alt="Facebook" /></Link>
    <Link target="_blank" to="https://www.instagram.com/talkndhealdotcom/"><img src={`${Domain_Url}/images/insta.png`} alt="Instagram" /></Link>
    <Link target="_blank" to="https://x.com/TalkndHeal"><img src={`${Domain_Url}/images/twitter.png`} alt="Twitter" /></Link>
    <Link target="_blank" to="https://www.linkedin.com/company/talk-nd-heal/"><img src={`${Domain_Url}/images/linkedin.png`} alt="LinkedIn" /></Link>
    <Link target="_blank" to="https://www.youtube.com/@TalkndHeal"><img src={`${Domain_Url}/images/yt.png`} alt="Youtube" /></Link> 

    <Link className='tooltip-container' to="javascript:void(0);">
    <img src={`${Domain_Url}/images/android.png`} alt="Android App" />
    <span class="tooltip-text">Coming Soon...</span>
    </Link>
    <Link className='tooltip-container' to="javascript:void(0);">
    <img src={`${Domain_Url}/images/apple.png`} alt="iOS App" />
    <span class="tooltip-text">Coming Soon...</span>
    </Link>
   

  </div>
  {/* left icons end */}
  <div className="right_side_img_div">
  <Link to="/kundliMatching"><img src="../images/gene01.png" alt="kundlimatching" className='img-fluid'/></Link>
  </div>
   {/* news letter div start  */}
   <div className="newlatter_div_left">
      <h2>TalkndHeal Newsletter</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group input-group-sm mb-2">
          <input
            type="email"
            className="form-control"
            placeholder="Enter Your Email Address"
            aria-label="Recipient's email"
            aria-describedby="button-addon2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button className="btn" type="submit" id="button-addon2">
            SUBSCRIBE
          </button>
        </div>
      </form>
      {status && <div>Subscription status: {status}</div>}
      
    </div>
  {/* news letter div end  */}
  <div className="right_side_img_div">
  <Link to="/services/vedic"><img src="../images/gene02.png" alt="vedic" className='img-fluid'/></Link>
  </div>



  </div>
</div>
{/* ===================col end right side 3 col========================  */}
</div>
{/* row end */}
</div>
{/* container end */}


{/* ===================== Verified Astrologers & Secure Payments start===================== */}
<section className="verified_astrologer_section">
  <div className="container">
    <div className="astrologer_title verified_title phone_heading_none">
      <h5>Verified Astrologers &amp; Secure Payments</h5>
    </div>
    <div className="row ">
    <div className="col-lg-12 col-md-12 col-12">
  <div className="main_div_flx_box">
    {verifiedfilter?.map((item, index) => (
      <Link to={item?.link} key={index}>
        <div className="sec-assurace-box">
          <div className='verified_img_box'>
            <img src={item.image} className='img-fluid' alt={item.verifiedti_name} />
          </div>
          <h3>{item.verifiedti_name}</h3>
          <p className="phone_txt_none">{item.verifiedddesc}</p>
        </div>
      </Link>
    ))}
  </div>
</div>

    </div>
  </div>
</section>
{/* ===================== Verified Astrologers & Secure Payments end===================== */}


</div>
{/* wrapper end */}
  {/* footer componets start */}
  {/* <ReloadModal/> */}
  <Footer/>
  <ToastContainer/>
      {/*footer componets end */}
</>
)
}

export default Home;
