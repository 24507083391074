function LoadingForChat() {
   
  
    return (
     
      <div className="d-flex gap-3 align-items-center justify-content-center text-danger">     
 <img src={require('../assets/img/Ultra-Compressed-Loader-Talkndheal.gif')} alt='loader'></img>

      </div>
    
    );
  }
  
  export default LoadingForChat;
  