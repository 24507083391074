import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getDecryptedCookie, isAuthenticated, isUserId, setEncryptedCookie } from '../components/Url/Url';
import { calculateTzApi, deleteKundaliApi, getPlaceOfBirth, saveKundaliApi } from '../AllApi/AllApi';
import { OpenSaveChartApi } from './AllapiData';
import Login from '../components/Login/Login';

const MoreOptions = () => {
  const locationD = useLocation();
  const [showLoginModal, setShowLoginModal] = useState();
  function toggleLoginModal() {
    setShowLoginModal(!showLoginModal);
  }
  // State to manage visibility of the more options
  const [showOptions, setShowOptions] = useState(false);
  const menuRef = useRef(null);
  // Function to toggle the visibility of more options
  const toggleOptions = () => {
    setShowOptions(prevState => !prevState);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };
 function editChart(){
  window.location.reload()
 }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const [placeOfBirth, setPlaceOfBirth] = useState('');
  const [latForKundli, setLatForKundli] = useState('');
  const [lonForKundli, setLonForKundli] = useState('');
  const [city, setCity] = useState('');
  const [cityId, setCityId] = useState('');
  const [placeList, setPlaceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const date=new Date()
  const defaultKundaliData = {
 
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
    day: date.getDate(),
    month: date.getMonth() + 1, // Months are 0-indexed, so we add 1
    year: date.getFullYear(),
  };
  const [kundaliData, setKundaliData] = useState({
    title:"Mr",
    gender:"Male",
    hours:defaultKundaliData?.hours,
    seconds:defaultKundaliData?.seconds,
    minutes:defaultKundaliData?.minutes,
    day:defaultKundaliData?.day,
    year:defaultKundaliData?.year,
    month:defaultKundaliData?.month,
  });
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
  
          // Update state with the current location
          setLocation({ latitude, longitude });
  
         
          
        },
        (error) => {
          // Geolocation was denied or an error occurred
          if (error.code === 1) {
            // alert('Please allow location to continue reading panchang.');
          } else {
            console.log('Error getting geolocation: ' + error.message);
          }
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }, []);
  const navigate = useNavigate();
  const [isFormComplete, setIsFormComplete] = useState(false);
  // Function to get Ayanamsa (example placeholder)
  const getAyanamsa = (latitude, longitude) => {
    // Implement the logic or use an API to get Ayanamsa
    return "N.C. LAHIRI 24°12'0\""; // Replace with actual Ayanamsa calculation or API call
  };
    const now = new Date();
  const defaultKundali = {
    title: 'Mr',
    hours: now.getHours(),
    minutes: now.getMinutes(),
    seconds: now.getSeconds(),
    day: now.getDate(),
    month: now.getMonth() + 1, // Months are 0-indexed, so we add 1
    year: now.getFullYear(),
  };

  // Function to format timezone as GMT offset
  const getFormattedTimezone = () => {
    const offset = new Date().getTimezoneOffset() / 60; // offset in hours
    const sign = offset > 0 ? "-" : "+";
    const absOffset = Math.abs(offset);
    const hours = Math.floor(absOffset);
    const minutes = (absOffset - hours) * 60;
    return `GMT ${sign}${hours}${minutes ? `:${minutes}` : ""}`;
  };

  // Function to handle changes and update data
  const kundaliDataChange = (e) => {
    const { name, value } = e.target;
  
    // Update Kundali data with input changes
    setKundaliData(prevData => {
      const updatedData = {
        ...prevData,
        [name]: value
      };
  
      // Set default values for missing fields
      return {
        
        Name: updatedData.Name || '',
        gender: updatedData.gender || '',
        day: updatedData.day || 1,
        month: updatedData.month || 1,
        year: updatedData.year || 1900,
        hours: updatedData.hours || 13,
        minutes: updatedData.minutes || 7,
        seconds: updatedData.seconds || 55,
        latitude: updatedData.latitude || 12.9716,
        place:updatedData.place,
        longitude: updatedData.longitude || 77.5946,
        timezone: updatedData.timezone || 5.5,
        ayanamsa: updatedData.ayanamsa || 'ayanamsa'
      };
    });
  
    // Extract updated values to check completeness
    const { day, month, year, hours, minutes, seconds } = {
      ...kundaliData,
      [name]: value // include the latest change
    };
  
    if (day && month && year && hours !== undefined && minutes !== undefined && seconds !== undefined) {
      // All required fields are filled, proceed with additional operations
  
      // Get timezone in GMT format
      const timezone = getFormattedTimezone();
  
      // Get latitude and longitude using Geolocation API
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
  
          // Get Ayanamsa based on latitude and longitude
          const ayanamsa = getAyanamsa(latitude, longitude);
  
          // Update kundaliData with latitude, longitude, timezone, and ayanamsa
          setKundaliData(prevData => ({
            ...prevData,
            latitude,
            longitude,
            timezone,
            ayanamsa,
          
          }));
        },
        (error) => {
          console.error('Error getting location', error);
          // Handle error or fallback here
        }
      );
    } else {
      // Not all fields are filled
      console.log('Please fill in all required fields');
    }
  
    // Check if form completion
    checkFormCompletion();
  };
  
  


   // Function to check if the form is complete
   const checkFormCompletion = () => {
    // , 'gender', 'day', 'month', 'year', 'hours', 'minutes', 'seconds', 'PlaceOfBirth' 
    const requiredFields = ['title', 'name'];
    const isComplete = requiredFields.every(field => kundaliData[field]);
    setIsFormComplete(isComplete);
  };

  // Function to generate options
  const generateOptions = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => (
      <option key={i + start} value={i + start}>
        {i + start}
      </option>
    ));
  };

  // Function to handle the generation of Kundali report
  const generateKundali = async(e) => {
    e?.preventDefault()
   
    // if (!isFormComplete) {
    //   toast.error('Please fill in all required fields.');
    //   return;
    // }
     
    setEncryptedCookie('kimf',{ ...kundaliData,cityId,city,lonForKundli,latForKundli});
   window.location.reload()
   
  };

  const [PlaceTimeZone, setPlaceTimeZone] = useState(null);

const timeZoneApi = async (longitude, latitude) => {
  try {
    const data = { lonForKundli: longitude, latForKundli: latitude };
    console.log(data, 'latlongdata');
    const res = await calculateTzApi(data);
    console.log(res?.timezone, "jasmann");

    if (res?.status === true) {
      setPlaceTimeZone(res?.timezone);  // Update state
      return res?.timezone;             // Return timezone
    }

    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
  async function generateViewKundali(event, ele) {
  event.preventDefault();  // Prevent default button behavior
    
  // Wait for the timezone API to finish and get the result
  const timez = await timeZoneApi(ele?.longitude, ele?.latitude);

  if (timez) {
    console.log(timez, 'working');
  } else {
    console.log(timez, 'not working');
  }

  console.log(timez, 'timesss');  // Log the fetched timezone

  // Prepare the object for the cookie
  const obj = {
    Name: ele?.name,
    ayanamsa: 'ayanamsa',
    city: ele?.PlaceOfBirth,
    cityId: ele?.cityId,
    day: ele?.day,
    gender: ele?.gender || 'male',
    hours: ele?.hour,
    latForKundli: ele?.latitude || 5.66,
    latitude: 12.9716,
    lonForKundli: ele?.longitude || 5.55,
    longitude: 77.5946,
    minutes: ele?.minute,
    month: ele?.month,
    seconds: ele?.second,
    timezone: timez || 5.5,  // Use the fetched timezone
    title: ele?.title || 'Ms',
    year: ele?.year,
  };

  // Set the encrypted cookie and navigate
  setEncryptedCookie('kimf', { ...obj });

  if (location.pathname === '/freeKundali') {
    navigate('/freekundalireport'); // Navigate to '/freekundalireport'
    window.location.reload();
  } else {
    navigate('/freekundalireport'); // Navigate to '/freekundalireport'
    window.location.reload();       // Reload after navigating to '/freekundalireport'
  }
}

  const deleteKundali = async (id) => {
    try {
      const token=isAuthenticated
      const res = await deleteKundaliApi(id,token);
      setTimeout(()=>{
        window.location.reload()
      },2000)
      
      // Optionally, update state or perform any other action upon successful deletion
    } catch (error) {
      console.error('Error deleting Kundali:', error);
      // Handle error if needed
    }
  };
  // const generateKundali = async (e) => {
//   e?.preventDefault();
 


//   try {
     
//       setEncryptedCookie('kimf', { ...kundaliData, cityId, city });

     
//       const userId=isUserId;
//       const token = isAuthenticated;
//       const dataSend = { ...kundaliData, cityId, city, token,userId };

      
//       const res = await saveKundaliApi(dataSend);

    
//       if (res.success) {
//           navigate('/freekundalireport');
//       } else {
       
//           toast.error('Failed to generate Kundali. Please try again.');
//       }
//   } catch (error) {
      
//       console.error('Error generating Kundali:', error);
//       toast.error('An error occurred. Please try again later.');
//   }
// };



  // Example months array
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  let debounceTimeout;

  const handleCityChange = (event) => {
    const newCity = event.target.value;
  
    // Clear previous debounce
    clearTimeout(debounceTimeout);
  
    // Update city and clear the place list
    setCity(newCity);
    setPlaceList([]);
  
    if (newCity.trim()) {
      // Set up debounce
      debounceTimeout = setTimeout(async () => {
        setLoading(true);
        setError(''); // Reset error state
  
        try {
          const result = await getPlaceOfBirth(newCity);
          setPlaceOfBirth(newCity); // Update the placeOfBirth with the current input
  
          if (result?.data && result.data.length > 0) {
            setPlaceList(result.data); // Update place list with API response if data exists
          } else {
            setPlaceList([]); // Clear the list if no data is returned
          }
  
        } catch (err) {
          setError('Failed to fetch place of birth');
          console.error('Error fetching place of birth:', err);
          setPlaceList([]); // Clear the list on error
        } finally {
          setLoading(false);
        }
      }, 300); // Adjust the delay (300ms is common, you can tweak this)
    } else {
      setPlaceOfBirth(''); // Clear placeOfBirth if input is empty
      setCity(''); // Clear city if input is empty
      setPlaceList([]); // Clear place list if input is empty
    }
  };
  
  const handleOptionClick = (data) => {
    const cityName = data?.name;
    const stateName = data?.state_name;
    const countryName = data?.country_name;
  
    // Store the formatted city, state, country string
    const formattedCity = `${cityName}, ${stateName}, ${countryName}`;
  
    setCity(formattedCity); // Set the city as a formatted string
    setCityId(data?.id); // Set the city ID
    setPlaceList([]); // Clear the list after selection
    setLatForKundli(data?.latitude)
    setLonForKundli(data?.longitude)
  };


  function submitKundali(){

  }

  // 
  const [opensavechart, setopensavechart] = useState('');

  useEffect(() => {
    const fetchOpensavechart = async () => {
      console.log('Fetching data...'); // Add this line
      const user=isUserId
      const token=isAuthenticated;
      const data={user,token}
      console.log(user,'datatatatat')
      try {
        const res = await OpenSaveChartApi(data);
        console.table(res?.data, 'sdfadsf');
        if (res?.success === true) {
          setopensavechart(res?.data);
        } else {
          console.error('API response was not successful:', res);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchOpensavechart();
  }, []);
// astrologer services api end
const  [calculatedTimeZoneData,setCalculatedTimeZoneData] =useState()
const kundaliDataReport=getDecryptedCookie('kimf') 
async function calculateTimeZoneForBoy(lat,lon,date){
  const reqSendBoyData={lat,lon,date}
  console.warn(kundaliDataReport,'kundaliDataReportkundaliDataReport')
  const res =await calculateTzApi(reqSendBoyData)
  console.log(res)
  setCalculatedTimeZoneData(res)
}
async function handlesaveKundli(){
  console.warn('tkk')
  const hours = kundaliDataReport?.hours ?? '02';
  const minutes = kundaliDataReport?.minutes ?? '01';
  const latForKundliData = kundaliDataReport?.latForKundli;
  const lonForKundliData = kundaliDataReport?.lonForKundli;
 
  const seconds =kundaliDataReport?.seconds ?? '02';
  const year = kundaliDataReport?.year
  const month = kundaliDataReport?.month
  const day = kundaliDataReport?.day

  const timeDay = `${hours}:${minutes}:${seconds}`;
  const DateDay =  `${day}/${month}/${year}`
  const user=isUserId
      const token=isAuthenticated;
      const userdata={user,token}
  const res2ReqSend = {
    day:day,
    month:month,
    year:year,
    seconds:seconds,
    minutes:minutes,
    hours:hours,
   
    Name:kundaliDataReport?.Name,
    pob:kundaliDataReport?.city,
    gender:kundaliDataReport?.gender,
    placeofbirthcity : kundaliDataReport?.city,
    placeofBirth : kundaliDataReport?.cityId,
    lat: latForKundliData,
    lon: lonForKundliData,
    tzone: calculatedTimeZoneData?.timezone,
    userdata: userdata
  };
try{
       const res= await saveKundaliApi(res2ReqSend)
       window.location.reload()
} catch (error) {
  console.error('Error fetching data:', error);
}
       
}


function handleNoKundli(){
  window.location.reload()
}

  // 
  
 
  return (
    <>
    
{/*  */}
<div>
<button className="more-option-toggle" onClick={toggleOptions}><i className="fas fa-list" /></button>

  {showOptions && (
 <div className="free-kundli-more-option" ref={menuRef}>


 {/*  */}
 <Link to="/freeKundali"><button onclick=""><i className="fas fa-file" />  <div className="more-option-tool-tip"> New Chart</div></button></Link>
 {/*  */}

 
 
 {/*  
 {locationD.pathname != '/freeKundali' &&<>
  {!isAuthenticated ? ( <button onClick={toggleLoginModal}>
    <i className="fa fa-pencil-square" />
    <div className="more-option-tool-tip">
    Edit Chart
    </div>
  </button>): (<button data-bs-toggle="modal" data-bs-target="#editchartmd" >
   <i className="fa fa-pencil-square" />
   <div className="more-option-tool-tip">
     Edit Chart
   </div>
 </button>)}
 </>
 }
 /*  */}
 
 {/*  *
 {locationD.pathname != '/freeKundali' &&
 <button data-bs-toggle="modal" data-bs-target="#editchartmd" >
   <i className="fa fa-pencil-square" />
   <div className="more-option-tool-tip">
     Edit Chart
   </div>
 </button>}
 /*  */}

 
 {/*  */}
 {locationD.pathname != '/freeKundali' &&<>
 {!isAuthenticated ?
  <button onClick={toggleLoginModal}>
    <i className="fa fa-floppy-o" />
    <div className="more-option-tool-tip">
      Save Chart
    </div>
  </button> :
  <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#savechartmd">
    <i className="fa fa-floppy-o" />
    <div className="more-option-tool-tip">
      Save Chart
    </div>
  </button>

}
</>}
 {/*  */}

 {/*  */} 
 {!isAuthenticated ?
  <button onClick={toggleLoginModal}>
    <i className="fas fa-folder-open" />
    <div className="more-option-tool-tip">
      Open Chart
    </div>
  </button> :
  <button type="button" data-bs-toggle="modal" data-bs-target="#openKundli">
    <i className="fas fa-folder-open" />
    <div className="more-option-tool-tip">
      Open Chart
    </div>
  </button>
}

 {/*  */}

 
</div>

  )}
 
</div>

{/*  */}


{/* OPEN CHARTS POP UP start */}
<div className='openkd_modal_div_m'>
<div className="modal fade" id="openKundli" tabIndex={-1}>
  <div className="modal-dialog modal-xl modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title"><i className="fas fa-folder-open" /> Open Chart</h5>
        <button type="button" className="fas fa-close save-kundli-close" data-bs-dismiss="modal" />
      </div>
      <div className="modal-body">
        <div className="kundli_modal_table">
          <table className="table">
            <tbody>
              <tr>
                <th>S.no.</th>
                <th>Name</th>
                <th>Gender</th>
                <th>DOB</th>
                <th>Time</th>
                <th>Place</th>
                <th>Saved On</th>
                <th colSpan={2}>Open Chart</th>
              </tr>
              {Array.isArray(opensavechart) && opensavechart.length > 0 ? (
  opensavechart?.map((ele, index) => (
    <tr key={index} onclick={()=>generateViewKundali(ele)}>
      <td>{ele?.id}</td>
      <td>{ele?.name}</td>
      <td>{ele?.gender}</td>
      <td>{ele?.day}/{ele?.month}/{ele?.year}</td>
      <td>{ele?.hour}:{ele?.minute}:{ele?.second}</td>
      <td>{ele?.PlaceOfBirth}</td>
      <td>{ele?.updated_at}</td>
      <td className='view_btn'> <button onClick={(e) => generateViewKundali(e, ele)}>View</button>
     </td>
      <td className='view_btn'> <button onClick={() => deleteKundali(ele?.id)}>Delete</button></td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="9">No data available</td>
  </tr>
)}

            </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
{/* Open Chart end modal  */}



{/* Save Chart start modal  */}
<div className='openkd_modal_div_m'>
<div className="modal fade" id="savechartmd" tabIndex={-1}>
  <div className="modal-dialog modal-md modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title"><i className="fas fa-folder-open" /> Save Chart</h5>
        <button type="button" className="fas fa-close save-kundli-close" data-bs-dismiss="modal" />
      </div>
      <div className="modal-body">
       <div className='save_chart_div_m'>
        <p>do you want to save this chart?</p>
        <div className='save_btn_div'>
          <button  className='btn btn-md' onClick={()=>handlesaveKundli()}>yes</button>
          <button type='submit' className='btn btn-md' onClick={handleNoKundli}>NO</button>
        </div>
       </div>
      </div>
    </div>
  </div>
</div>
</div>
{/* Save Chart end modal  */}


{/* Edit Chart start modal  */}
<div className='openkd_modal_div_m'>
<div className="modal fade" id="editchartmd" tabIndex={-1}>
  <div className="modal-dialog modal-lg modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title"><i className="fas fa-folder-open" /> Edit Chart</h5>
        <button type="button" className="fas fa-close save-kundli-close" data-bs-dismiss="modal" />
      </div>
      <div className="modal-body">
      <form action="freeKundali" method="POST" id="myForm" autofill="off">
<div className="kundlibirth_form_main_div">
{/* nested row start */}
<div className="row">
{/* nested col start */}
<div className="col-lg-12">
<div className="kundliform_title">
<h4>Get Your Detailed Birth Chart</h4>
<hr/>
</div>
</div>
{/* nested col end */}
{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
<label>Name</label>
<div className="input-group mb-3">
 <select className="slct_form" name="title" value={kundaliData?.title} onChange={kundaliDataChange} id="inputGroupSelect01">
        <option value="">Select One</option>
        <option value="Mr">Mr.</option>
        <option value="Ms">Ms.</option>
        <option value="Mrs">Mrs.</option>
      </select>
<input type="text" className="form-control" id="name" name="Name" onChange={kundaliDataChange} placeholder="Name" required />
</div>
</div>
{/* nested col end */}
{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
<label >Gender</label>
<select
        id="inputState"
        onChange={kundaliDataChange}
        className="form-select"
        name="gender"
        value={kundaliData?.gender}
        required
      >
        <option value="">Select Gender</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
      </select>
</div>
{/* nested col end */}
{/* nested col start */}
<div className="col-md-6">
<label>Date Of Birth</label>
<div className="dob-inputs">
      <select
        className="form-select"
        name="day"
        value={kundaliData?.day}
        onChange={kundaliDataChange}
        required
      >
        <option value="" disabled>Day</option>
        {generateOptions(1, 31)}
      </select>
      
      <select
        className="form-select"
        name="month"
        value={kundaliData?.month}
        onChange={kundaliDataChange}
        required
      >
        <option value="" disabled>Month</option>
        {months.map((month, index) => (
          <option key={index + 1} value={index + 1}>
            {month}
          </option>
        ))}
      </select>
      
      <select
        className="form-select"
        name="year"
        value={kundaliData?.year}
        onChange={kundaliDataChange}
        required
      >
        <option value="" disabled>Year</option>
        {generateOptions(1900, 2024)}
      </select>
    </div>
</div>
{/* nested col end */}

{/* nested col start */}
<div class="col-md-6">
  <label>Time Of Birth (24 Hour Format)</label>
  <div class="tob-inputs">
    <select class="form-select" name="hours" onChange={kundaliDataChange}  required>
      <option disabled>Hrs.</option>
      {/* <!-- Generate hours from 0 to 23 --> */}
      {[...Array(24).keys()].map(hour => (
        <option value={hour} key={hour} selected={hour === 13}>{hour}</option>
      ))}
    </select>

    <select class="form-select" name="minutes" onChange={kundaliDataChange} required>
      <option disabled>Mins.</option>
      {/* <!-- Generate minutes from 0 to 59 --> */}
      {[...Array(60).keys()].map(minute => (
        <option value={minute} key={minute} selected={minute === 7}>{minute}</option>
      ))}
    </select>

    <select class="form-select" name="seconds"  onChange={kundaliDataChange} required>
      <option disabled>Secs.</option>
      {/* <!-- Generate seconds from 0 to 59 --> */}
      {[...Array(60).keys()].map(second => (
        <option value={second} key={second} selected={second === 55}>{second}</option>
      ))}
    </select>
  </div>
</div>

{/* nested col end */}

{/* nested col start */}

<div className="col-md-12">
  <div className='placbirth_parent_div'>
      <label htmlFor="placeOfBirth">Place Of Birth</label>
      <div className="placeOfBirthInputDiv">
        <input type="text" className="form-control" value={city} onChange={handleCityChange}
          placeholder="Place Of Birth *" name="PlaceOfBirth" id="placeOfBirth" required          autoComplete="off" aria-haspopup="true" aria-expanded={placeList?.length > 0}
          aria-controls="placeOfBirthList"/>
        <input type="hidden" name="city_id" id="city_id" value={cityId || ''} required />
        {placeList?.length > 0 && (
          <div id="placeOfBirthList" className="placeOfBirthList scroll-style list_div_sug"
            role="listbox">
            {placeList.map((option) => (
              <div key={option.id} className="placeOfBirthListItem" onClick={() => handleOptionClick(option)} role="option" aria-selected={cityId === option.id}
              > {option.name} {option.state} {option.country}
              </div>
            ))}
          </div>
        )}
      </div>
      </div>
    </div>

  {/* nested col end */}

  {/* nested col start */}
  <div className="col-12">
  <div className={`horo-btn ${!isFormComplete ? 'disabled' : ''}`}>
    <button type="submit" name="generate" onClick={generateKundali}
    
    >Generate Kundli</button>
  </div>
</div>


{/* nested col end */}
</div>
{/* nested row end */}
</div>
</form>
      </div>
    </div>
  </div>
</div>
</div>
{/* Edit Chart end modal  */}
{ showLoginModal && <Login closeModal={toggleLoginModal} />}

    </>
  );
};

export default MoreOptions;
