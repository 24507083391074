import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import he from 'he';

export const  cookieDomain=process.env.REACT_APP_COOKIE_DOMAIN 
// export const cookieDomain = ''; 
// ,

//   "proxy": "https://healfate.com/custom_api"
 export const domainName=process.env.REACT_APP_DOMAIN_NAME;
 export const Domain_Url=process.env.REACT_APP_DOMAIN_URL;
// url 
//export const domainName = `http://192.168.1.22:3000`
//export const Domain_Url=`http://192.168.1.22:3000`

// Secret pass key for encryption/decryption
const SECRET_KEY =process.env.REACT_APP_SECRET_KEY

// Function to encrypt data
export const encryptData = (data) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
    return encrypted;
  } catch (error) {
    console.error('Encryption failed', error);
    return null;
    // eff
  }
};

// Function to set encrypted data in a cookie
export const setEncryptedCookie = (key, data) => {
  // huhuhuhu 
  try {
    const encryptedData = encryptData(data);
    if (encryptedData) {
      Cookies.set(key, encryptedData, { expires: 7 }); // Cookie expires in 7 days
    } else {
      console.error('Failed to encrypt data');
    }
  } catch (error) {
    console.error('Failed to set cookie', error);
  }
};





 // Function to decrypt data
export const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decrypted;
  } catch (error) {
    console.error('Decryption failed', error);
    return null;
  }
};

// Function to get and decrypt data from a cookie
export const getDecryptedCookie = (key) => {
  try {
    const encryptedData = Cookies.get(key);
    if (encryptedData) {
      return decryptData(encryptedData);
    }
    return null;
  } catch (error) {
    console.error('Failed to get or decrypt cookie', error);
    return null;
  }
};


  export const isAuthenticated=getDecryptedCookie('token')
  export const isUserId=getDecryptedCookie('user')

  //
  export const removeHtmlTags = (text) => {
    if (!text) return "";
    const decodedText = he.decode(text); // Decode any HTML entities
    const plainText = decodedText.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
    return plainText;
  };

  export const truncatecallText = (text, maxLength) => {
    return text.length > maxLength ? text?.slice(0, maxLength) + "..." : text;
  };
