import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../CssFiles/Editprofile.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  editProfile,
  getPlaceOfBirth,
  getUserDetailsApi,
  postEditProfile,
} from "../AllApi/AllApi";
import { decryptData, isAuthenticated, isUserId } from "../components/Url/Url";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Editprofile = () => {
  const navigate = useNavigate();
  const token = isAuthenticated; // Use `isAuthenticated` as a value
  const userId = isUserId; // Fetch the user ID

  // Redirect if token or userId is null
  useEffect(() => {
    if (token === null || userId === null) {
      navigate("/");
    }
  }, [token, userId, navigate]);

  const [loginRes, setLoginRes] = useState({
    changedProfile: "",
  });
  const isValidDate = (day, month, year) => {
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() + 1 === month &&
      date.getDate() === day
    );
  };

  const [searchTermPlace, setSearchTermPlace] = useState(null);
  const [isOpenPlace, setIsOpenPlace] = useState(false);
  const [selectedOptionPlace, setSelectedOptionPlace] = useState(null);
  const [placeData, setPlaceData] = useState([]);
  const [profileImage, setProfileImage] = useState(null);

  const handleInputPlaceChange = (e) => {
    const inputValue = e.target.value;

    // Set the search term in state
    setSearchTermPlace(inputValue);

    // If input value length is less than 3, do not call API
    if (inputValue.length >= 3) {
      setIsOpenPlace(true);
    } else {
      setIsOpenPlace(false); // Close the dropdown if fewer than 3 characters
      setPlaceData([]); // Clear the place data if input is cleared
    }

    if (inputValue === "") {
      setSelectedOptionPlace(null); // Clear selected option if input is empty
      setPlaceData([]); // Clear place data
      setIsOpenPlace(false); // Close dropdown
    }
  };

  const handleOptionClickPlace = (place, setFieldValue) => {
    const formattedPlace = `${place?.name}, ${place?.state_name}, ${place?.country_name}`;
    console.log(place, "PlaceData");
    setSearchTermPlace(formattedPlace); // Set the full place name in the input field
    setSelectedOptionPlace(formattedPlace); // Optionally set the selected option

    const placeOfBirthDetails = JSON.stringify({
      place_id: place?.id,
      latitude: place?.latitude,
      longitude: place?.longitude,
      name: formattedPlace,
    });

    setFieldValue("PlaceOfBirth", placeOfBirthDetails);
    setIsOpenPlace(false);
  };

  useEffect(() => {
    const getFilteredPlace = async () => {
      try {
        const response = await getPlaceOfBirth(searchTermPlace);
        console.log(response);

        if (response?.data?.length > 0) {
          setPlaceData(response?.data);
        } else {
          setPlaceData([]); // Clear data if no results
        }
      } catch (error) {
        console.log("Error fetching 'from' data: ", error);
      }
    };
    getFilteredPlace();

    if (searchTermPlace !== "") {
      getFilteredPlace();
    } else {
      setPlaceData([]);
    }
  }, [searchTermPlace]);

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .required("Name is required"),
    isdcode: Yup.string().required("ISD Code is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Invalid phone number"),
    email: Yup.string().email("Invalid email address"),
    gender: Yup.string().required("Gender is required"),
    day: Yup.number()
      .required("Day is required")
      .test("is-valid-day", "Invalid day", function (value) {
        const { month, year } = this.parent;
        return isValidDate(value, month, year);
      }),
    month: Yup.number().min(1).max(12).required("Month is required"),
    year: Yup.number()
      .min(1900)
      .max(new Date().getFullYear())
      .required("Year is required"),
    hour: Yup.number().min(0).max(23),
    minute: Yup.number().min(0).max(59),
    second: Yup.number().min(0).max(59),
    occupation: Yup.string().required("Occupation is required"),
    PlaceOfBirth: Yup.string().required("Place Of Birth is required"),
    maritalstatus: Yup.string().required("Marital Status is required"),
  });

  const [profilePreview, setProfilePreview] = useState(null);

  // Use Effect to load the initial profile image if available
  useEffect(() => {
    if (loginRes?.profile) {
      setProfilePreview(loginRes.profile); // Set the user's profile image if exists
    }
  }, [loginRes]);

  const [changedProfile, setChangedProfile] = useState(false); // Set to false initially

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-IN").format(amount);
  };

  async function userDetails() {
    const token = isAuthenticated; // Use `isAuthenticated` as a value
    const userId = isUserId; // Fetch the user ID

    try {
      const res = await getUserDetailsApi(token, userId);
      console.log(res , "getUserDetailsApi");
      setLoginRes(res?.data || {});
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }

  const editChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      // Create a URL for image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    userDetails();
  }, [changedProfile]);

  const updatedProfile = async (values, actions) => {
    try {
      console.log(values);
      const token = isAuthenticated; // Use `isAuthenticated` as a value, not a function
      if (!token) {
        throw new Error("User not authenticated");
      }
  
      const formData = new FormData(); // Use FormData for sending the image and other fields
      
      // Append fields only once
      formData.append("title", values.title);
      formData.append("name", values.name);
      formData.append("isdcode", values.isdcode);
      formData.append("phone", values.phone);
      formData.append("email", values.email);
      formData.append("gender", values.gender);
      formData.append("day", values.day);
      formData.append("month", values.month);
      formData.append("year", values.year);
      formData.append("hour", values.hour || ""); // Optional fields
      formData.append("minute", values.minute || "");
      formData.append("second", values.second || "");
      formData.append("occupation", values.occupation || "");
      formData.append("maritalstatus", values.maritalstatus || "");
  
      // Append userid only once
      formData.append("userid", loginRes?.id); 
  
      // Handle PlaceOfBirth logic: if user hasn't changed it, use existing data from loginRes
      let placeOfBirthObj;
      if (selectedOptionPlace) {
        try {
          // Try to parse PlaceOfBirth as JSON if user has changed it
          placeOfBirthObj = JSON.parse(values.PlaceOfBirth);
        } catch (e) {
          placeOfBirthObj = { name: values.PlaceOfBirth };
        }
  
        // Append PlaceOfBirth-related fields from user's input
        formData.append("place_of_birth", placeOfBirthObj?.place_id || "");
        formData.append("PlaceOfBirth", placeOfBirthObj?.name || "");
        formData.append("latitude", placeOfBirthObj?.latitude || "");
        formData.append("longitude", placeOfBirthObj?.longitude || "");
      } else {
        // If PlaceOfBirth wasn't changed, use the existing data from loginRes
        formData.append("place_of_birth", loginRes?.place_of_birth || "");
        formData.append(
          "PlaceOfBirth",
          `${loginRes?.PlaceOfBirth}` || ""
        );
        formData.append("latitude", loginRes?.latitude || "");
        formData.append("longitude", loginRes?.longitude || "");
      }
  
      // Handle profile image logic
      if (profileImage) {
        formData.append("profile", profileImage); // Append the new image file
      } else if (loginRes?.profileImage) {
        formData.append("profile", loginRes?.profileImage); // Include existing image if no new image is selected
      }
  
      const res = await postEditProfile(token, formData); // Send FormData
  
      console.log(res);
  
      // Handle the success response
      if (res?.message === "Profile updated successfully") {
        toast.success(res?.message);
        setChangedProfile(true); // Trigger the user details update
        navigate("/userprofile");
      } else {
        throw new Error(res?.message || "Profile update failed");
      }
  
      actions.setSubmitting(false);
    } catch (error) {
      toast.error(error.message);
      actions.setSubmitting(false);
    }
  };

  // const updatedProfile = async (values, actions) => {
  //   try {
  //     console.log(values);
  //     const token = isAuthenticated; // Use `isAuthenticated` as a value, not a function
  //     if (!token) {
  //       throw new Error("User not authenticated");
  //     }

  //     const formData = new FormData(); // Use FormData for sending the image and other fields
  //     for (let key in values) {
  //       formData.append(key, values[key]);
  //       formData.append("userid", loginRes?.id);
  //     }

  //     for (let key in values) {
  //       if (key === "PlaceOfBirth") {
  //         // Parse the string back to an object to get place_id, latitude, and longitude
  //         const placeOfBirthObj = JSON.parse(values[key]);

  //         formData.append("place_of_birth", placeOfBirthObj?.place_id || "");
  //         formData.append("PlaceOfBirth", placeOfBirthObj?.name || "");
  //         formData.append("latitude", placeOfBirthObj?.latitude || "");
  //         formData.append("longitude", placeOfBirthObj?.longitude || "");
  //       } else {
  //         formData.append(key, values[key]);
  //       }
  //     }

  //     if (profileImage) {
  //       // Check if there is a new profile image
  //       formData.append("profile", profileImage); // Append the new image file
  //     } else if (loginRes.profileImage) {
  //       formData.append("profile", loginRes?.profileImage); // Include existing image if no new image is selected
  //     }

  //     const res = await postEditProfile(token, formData); // Send FormData

  //     console.log(res);

  //     //  message: 'Profile updated successfully'

  //     if (res?.message === "Profile updated successfully") {
  //       toast.success(res?.message);
  //       setChangedProfile(true); // Trigger the user details update
  //       navigate("/userprofile");
  //     } else {
  //       throw new Error(res?.message || "Profile update failed");
  //     }

  //     actions.setSubmitting(false);
  //   } catch (error) {
  //     toast.error(error.message);
  //     actions.setSubmitting(false);
  //   }
  // };

  return (
    <>
      <ToastContainer />
      <Header />

      {/*edit profile page*/}
      <div className="container">
        <div className="row ">
          <div className="col-md-12 col-sm-12">
            <div className="astrology_title_div mt-3">
              <h1 className="fs-5">edit Profile</h1>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <section className="edit_profile_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="edit_pro_main_div_bg">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="edit_input_userimg_div_main">
                    {/* Image Preview */}
                    <img
                      src={profilePreview || "../images/a1.png"}
                      alt="Profile"
                      style={{
                        height: 105,
                        width: 105,
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />

                    <div className="upload_div_main">
                      <div className="input_usr_updd">
                        {/* File Input with 'profile' tag name */}
                        <input
                          type="file"
                          name="profile" // Ensure 'name' is set to 'profile'
                          id="imageInput"
                          accept="image/*"
                          onChange={editChange} // Attach the handler here
                          style={{ display: "none" }} // Hide the default file input
                        />
                        <label
                          htmlFor="imageInput"
                          className="uploadLabel"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-camera" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    title: loginRes.title || "Mr.",
                    name: loginRes.name || "",
                    isdcode: loginRes.isdcode || "+91",
                    phone: loginRes.mobile || "",
                    email: loginRes.email || "",
                    gender: loginRes.gender || "",
                    day: loginRes.day || "",
                    month: loginRes.month || "",
                    year: loginRes.year || "",
                    hour: loginRes.hour || "",
                    minute: loginRes.minute || "",
                    second: loginRes.second || "",
                    PlaceOfBirth: loginRes?.PlaceOfBirth || "",
                    occupation: loginRes.occupation || "",
                    maritalstatus: loginRes?.maritalstatus || "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={updatedProfile}
                >
                  {({ isSubmitting, values, handleChange, setFieldValue }) => (
                    <Form>
                      <div>
                        <div className="row ">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label>
                              <span>Name</span>
                              <span className="text-danger ps-1">*</span>
                            </label>
                            <div className="d-flex">
                              <div>
                                <Field
                                  as="select"
                                  name="title"
                                  style={{ height: "40px" }}
                                  value={loginRes?.title || ""}
                                >
                                  {/* Conditionally render the "Mr." option */}
                                  {loginRes?.title === "Mr." ? (
                                    <option value="Mr." selected>Mr.</option>
                                  ) : (
                                    <option value="Mr.">Mr.</option>
                                  )}

                                  {/* Conditionally render the "Mrs." option */}
                                  {loginRes?.title === "Mrs." ? (
                                    <option value="Mrs." selected>Mrs.</option>
                                  ) : (
                                    <option value="Mrs.">Mrs.</option>
                                  )}

                                  {/* Conditionally render the "Ms." option */}
                                  {loginRes?.title === "Ms." ? (
                                    <option value="Ms." selected>Ms.</option>
                                  ) : (
                                    <option value="Ms.">Ms.</option>
                                  )}
                                </Field>
                                <ErrorMessage name="title" component="div" />
                              </div>

                              <div className="w-100">
                                <Field
                                  type="text"
                                  name="name"
                                  className="p-0 px-2 m-0"
                                  placeholder="Enter Name"
                                  style={{ height: "40px", width: "100%" }}
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label>
                              <span>Email</span>
                              <span className=" ps-1">(optional)</span>
                            </label>
                            <div className="w-100">
                              <Field
                                type="text"
                                name="email"
                                className="p-0 px-2 m-0"
                                placeholder="Enter you email"
                                style={{ height: "40px", width: "100%" }}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label>
                              <span>Mobile</span>
                            </label>
                            <div className="d-flex">
                              <div>
                                <Field
                                  as="select"
                                  name="isdcode"
                                  style={{ height: "40px" }}
                                  value={loginRes?.countrycode}
                                  readOnly
                                >
                                  <option value="+91" selected>
                                    +91
                                  </option>
                                </Field>
                                <ErrorMessage name="title" component="div" />
                              </div>

                              <div className="w-100">
                                <Field
                                  type="text"
                                  name="phone"
                                  className="p-0 px-2 m-0"
                                  placeholder="Phone Number"
                                  style={{ height: "40px", width: "100%" }}
                                  readOnly
                                />
                                <ErrorMessage
                                  name="phone"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label>
                              <span>Gender</span>
                              <span className="text-danger ps-1">*</span>
                            </label>
                            <div className=" w-100">
                              <Field
                                as="select"
                                name="gender"
                                style={{ height: "40px", width: "100%" }}
                              >
                                <option value="" label="Select gender" />
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </Field>
                              <ErrorMessage
                                name="gender"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label>
                              <span>Date of Birth</span>
                              <span className="text-danger ps-1">*</span>
                            </label>
                            <div className="d-flex w-100">
                              <div className="col">
                                <Field
                                  type="number"
                                  name="day"
                                  placeholder="DD"
                                  style={{ width: "100%" }}
                                  min="0" // Set minimum value to 0
                                  max="59" // Optional: Set max to ensure the seconds are valid
                                />
                                <ErrorMessage
                                  name="day"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                              <div className="col gap-2 px-2">
                                <Field
                                  type="number"
                                  name="month"
                                  placeholder="MM"
                                  style={{ width: "100%" }}
                                  min="0" // Set minimum value to 0
                                  max="12" // Optional: Set max to ensure the seconds are valid
                                />
                                <ErrorMessage
                                  name="month"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                              <div className="col">
                                <Field
                                  type="number"
                                  name="year"
                                  placeholder="YYYY"
                                  style={{ width: "100%" }}
                                  min="1900" // Set minimum value to 0
                                  max="2023" // Optional: Set max to ensure the seconds are valid
                                />
                                <ErrorMessage
                                  name="year"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label>
                              <span>Time of Birth</span>
                              <span className="text-danger ps-1">*</span>
                            </label>
                            <div className="d-flex">
                              <div className="col">
                                <Field
                                  type="number"
                                  name="hour"
                                  placeholder="HH"
                                  style={{ width: "100%" }}
                                  min="0" // Set minimum value to 0
                                  max="23" // Optional: Set max to ensure the seconds are valid
                                />
                                <ErrorMessage
                                  name="hour"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>

                              <div className="col gap-2 px-2">
                                <Field
                                  type="number"
                                  name="minute"
                                  placeholder="MM"
                                  style={{ width: "100%" }}
                                  min="0" // Set minimum value to 0
                                  max="59" // Optional: Set max to ensure the seconds are valid
                                />
                                <ErrorMessage
                                  name="minute"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>

                              <div className="col">
                                <Field
                                  type="number"
                                  name="second"
                                  placeholder="SS"
                                  style={{ width: "100%" }}
                                  min="0" // Set minimum value to 0
                                  max="59" // Optional: Set max to ensure the seconds are valid
                                />
                                <ErrorMessage
                                  name="second"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label>
                              <span>Occupation</span>
                              <span className="text-danger ps-1">*</span>
                            </label>
                            <div className=" w-100 ">
                              <Field
                                as="select"
                                name="occupation"
                                style={{ height: "40px", width: "100%" }}
                              >
                                <option value="" >Select</option>
                                <option value="Student">Student</option>
                                <option value="Employed">Employed</option>
                                <option value="Not Employed">
                                  Not Employed
                                </option>
                              </Field>
                              <ErrorMessage name="occupation" component="div" className="text-danger" />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label>
                              <span>Marital Status</span>
                              <span className="text-danger ps-1">*</span>
                            </label>
                            <div className="w-100 ">
                              <Field
                                as="select"
                                name="maritalstatus"
                                style={{ height: "40px", width: "100%" }}
                              >
                                <option value="" >Select</option>
                                <option value="Married">Married</option>
                                <option value="Unmarried">Unmarried</option>
                                <option value="Divorced">Divorced</option>
                                <option value="Widow">Widow</option>
                              </Field>
                              {/* <ErrorMessage name="occupation" component="div" /> */}
                              <ErrorMessage name="maritalstatus" component="div" className="text-danger" />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <label>
                              <span>Place of Birth</span>
                              <span className="text-danger ps-1">*</span>
                            </label>
                            <Field
                              name="PlaceOfBirth"
                              type="text"
                              className="w-100"
                              value={
                                searchTermPlace === null
                                  ? loginRes?.PlaceOfBirth
                                  : searchTermPlace
                              }
                              onChange={handleInputPlaceChange}
                              placeholder="Search..."
                              style={{ height: "40px", width: "100%" }}
                            />
                            <ErrorMessage
                              name="PlaceOfBirth"
                              component="div"
                              className="text-danger"
                            />
                            {isOpenPlace && (
                              <div
                                style={{
                                  maxHeight: "150px",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  border: "1px solid gray",
                                }}
                              >
                                {placeData.length > 0 ? (
                                  placeData.map((place, index) => (
                                    <div
                                      key={index}
                                      onClick={() =>
                                        handleOptionClickPlace(
                                          place,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <span>{place?.name},</span>
                                      <span>{place?.state_name},</span>
                                      <span>{place?.country_name}</span>
                                    </div>
                                  ))
                                ) : (
                                  <p>No place data available</p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className=" col-12">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*edit profile page*/}

      <Footer />
    </>
  );
};

export default Editprofile;
