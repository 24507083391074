import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link, useNavigate } from 'react-router-dom';
import { sliderrigtside } from '../data/Owlcarouseloptions';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Wallet.css';
import { allAllPricing, getUserDetailsApi } from '../AllApi/AllApi';
import { decryptData, Domain_Url, isAuthenticated, isUserId } from '../components/Url/Url';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import LoadingWallet from '../components/LoadingComponent/LoadingWallet';

const Wallet = () => {
  const callbackurl=process.env.REACT_APP_DOMAIN_URL+'/wallet';
  const cancelurl=process.env.REACT_APP_DOMAIN_URL+'/wallet';
  const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;
const navigate = useNavigate();
const [allPricingData, setAllPricingData] = useState([]);
const [responseStatus, setResponseStatus] = useState(false);
const [loginRes, setLoginRes] = useState({});
const [verifyRes, setVerifyRes] = useState('');
const [Loadingwallet, setLoadingwallet] = useState(true);
// const userId = isUserId;
const token = isAuthenticated; // Use `isAuthenticated` as a value
  const userId = isUserId; // Fetch the user ID

  // Redirect if token or userId is null
  useEffect(() => {
    if (token === null || userId === null) {
      navigate("/");
    }
  }, [token, userId, navigate]);
useEffect(() => {
  async function fetchAllPricing() {
    try {
      const res = await allAllPricing();
      if (res?.success === true) {
        setLoadingwallet(false);
        setAllPricingData(res?.data);
      }
    } catch (error) {
      toast.error('Failed to fetch pricing data');
    }
  }
  fetchAllPricing();
}, []);

useEffect(() => {
  async function userDetails() {
    try {
      const decToken = isAuthenticated;
      const res = await getUserDetailsApi(decToken, userId);
      setLoginRes(res);
    } catch (error) {
      toast.error('Failed to fetch user details');
    }
  }
  userDetails();
}, [responseStatus]);

const formatAmount = (amount) => {
  if (isNaN(amount) || amount === null || amount === undefined) {
    return '-';
  }
  return new Intl.NumberFormat("en-IN").format(amount);
};

//  handlePayment function 
const handlePayment = async (id) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    toast.error("Razorpay SDK failed to load. Please check your connection.");
    return;
  }

  try {
    const { data } = await axios.post(
      `${API_URL}/razorpayAPIs/create-order`,
      { payment: id, userId },
      {
        headers: {
          apiKey: API_KEY,
          Authorization: `Bearer ${isAuthenticated}`,
        },
      }
    );

    const options = {
      key: process.env.REACT_APP_RAZORPAY_TEST_KEY,
      secret: process.env.REACT_APP_RAZORPAY_SECRET_KEY,
      amount: data.amount, // Amount in paise
      currency: 'INR',
      name: 'Talkndheal',
      description: 'Payment for Order',
      order_id: data.order_id,
      handler: async (response) => {
        // console.warn(response)
        try {
          const verifyResponse = await axios.post(
            `${API_URL}/razorpayAPIs/verify-payment`,
            {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              user_id: userId,
            },
            {
              headers: {
                apiKey: API_KEY,
                Authorization: `Bearer ${isAuthenticated}`,
              },
            }
          );

          if (verifyResponse?.data?.success) {
            setResponseStatus(true);
            toast.success(verifyResponse.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast.error("Payment verification failed. Please try again.");
          }
        } catch (error) {
          console.error("Payment Verification Error:", error);
          toast.error('Payment Verification Failed');
        }
      },
      prefill: {
        name: loginRes?.data?.name || '',
        email: loginRes?.data?.email || '',
        contact: loginRes?.data?.phone || '',
      },
      // theme: {
      //   color: '#F37254',
      // },
      callback_url: callbackurl,
      cancel_url: cancelurl,
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  } catch (error) {
    console.error("Order Creation Error:", error);
    toast.error('Error in creating order');
  }
};

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};



  return (
    <>
      <ToastContainer />
      <Header />
    <section className="wallet_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
              <div className="right_side_main_div_sticky mb-2">
                <div className="sidebar_main_div_sld">
                  <OwlCarousel className="owl-theme" {...sliderrigtside}>
                  

{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/horoscope/today-horoscope"><img src="../../images/sld03.png" alt="astrologer"/></Link> 
    </div> 
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/panchang"><img src="../../images/sld04.png" alt="astrologer"/></Link> 
    </div> 
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/kundliMatching"><img src="../../images/sld05.png" alt="astrologer"/></Link> 
    </div> 
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/services/vedic"><img src="../../images/sld06.png" alt="astrologer"/></Link> 
    </div> 
</div>
{/* item div end */}
                  </OwlCarousel>
                </div>
{/*  */}

                <div className="sidebar_main_div_sld2">
                  <OwlCarousel className="owl-theme" {...sliderrigtside}>
                  {/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/horoscope/today-horoscope"><img src="../images/small01.png" alt="today horoscope"/></Link> 
    </div> 
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/services/vedic"><img src="../images/small02.png" alt="vedic"/></Link> 
    </div> 
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/kundliMatching"><img src="../images/small03.png" alt="kundlimatching"/></Link> 
    </div> 
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to="/panchang"><img src="../images/small04.png" alt="panchang"/></Link> 
    </div> 
</div>
{/* item div end */}
                  </OwlCarousel>
                </div>
                <div className="app_download">
                  <h2>TalkndHeal on Mobile</h2>
                  <Link to="">Download Now</Link>
                </div>
                <div className="left_icons_so">
                 
                <Link target="_blank" to="https://www.facebook.com/talkndhealofficial/"><img src={`${Domain_Url}/images/fb.png`} alt="Facebook" /></Link>
    <Link target="_blank" to="https://www.instagram.com/talkndhealdotcom/"><img src={`${Domain_Url}/images/insta.png`} alt="Instagram" /></Link>
    <Link target="_blank" to="https://x.com/TalkndHeal"><img src={`${Domain_Url}/images/twitter.png`} alt="Twitter" /></Link>
    <Link target="_blank" to="https://www.linkedin.com/company/talk-nd-heal/"><img src={`${Domain_Url}/images/linkedin.png`} alt="LinkedIn" /></Link>
    <Link target="_blank" to="https://www.youtube.com/@TalkndHeal"><img src={`${Domain_Url}/images/yt.png`} alt="Youtube" /></Link> 

    <Link className='tooltip-container' to="javascript:void(0);">
    <img src={`${Domain_Url}/images/android.png`} alt="Android App" />
    <span class="tooltip-text">Coming Soon...</span>
    </Link>
    <Link className='tooltip-container' to="javascript:void(0);">
    <img src={`${Domain_Url}/images/apple.png`} alt="iOS App" />
    <span class="tooltip-text">Coming Soon...</span>
    </Link>
                </div>
              </div>
            </div>

           <div className="col-lg-9 col-md-9 col-sm-12 col-12">
           {!Loadingwallet ? (<div className="row">
                <div className="col-lg-12 col-md-5">
                  <div className="wallet_head">
                    <h3><i className="fas fa-wallet"/> Available Balance <span> ₹ {formatAmount(loginRes?.data?.wallet)}</span></h3>
                    <Link to="/orderhistory" className='d-none'>Consultation History</Link>
                  </div>  
                </div>
                <div className="col-lg-12 col-md-5">
                  <div className="wallet_title">
                    <h2>Add Money To Wallet</h2>   
                    <h3>Choose from the available recharge pack</h3>
                  </div>  
                </div>

                {allPricingData?.map((ele, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-4 col-4" key={index}>
                    <div className="recharge_box_main_div">
                      <Link onClick={() => handlePayment(ele?.id)}>
                        <h3>₹{ele?.price}</h3>
                        <h4>Pay Now</h4>
                        <span>{ele?.getamount === '0' ? 'No Extra' : `${ele?.getamount}% Extra`}</span>   
                      </Link>
                    </div>
                  </div>
                ))}

              </div>) : (<LoadingWallet/>)}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Wallet;
