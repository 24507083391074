import React from 'react'

const LoadingAstrologyService = () => {
  return (
    <>
    
    <div className='wallet_loader_main_div service_loader_img'>
        <div className='img_loader_div'>
        <img src={require('../assets/img/loaderimg.png')} alt='loader'/>
        <div className='loader_circle'></div>
        </div>
       </div>
    </>
  )
}

export default LoadingAstrologyService
