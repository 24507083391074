// chat api ===================start


const REACT_APP_JSON_API_URL=process.env.REACT_APP_JSON_API_URL
const REACT_APP_KUNDALI_CLICK_API_URL=process.env.REACT_APP_KUNDALI_CLICK_API_URL
const API_URL=process.env.REACT_APP_API_URL
const API_KEY=process.env.REACT_APP_API_KEY
const REACT_APP_USER_NAME =process.env.REACT_APP_USER_NAME
const REACT_APP_USER_PASSWORD =process.env.REACT_APP_USER_PASSWORD
const REACT_APP_AUTH_CODE =process.env.REACT_APP_AUTH_CODE
const REACT_APP_USER_ID =process.env.REACT_APP_KUNDALI_CLICK_USER_ID

export const fetchastrochatData = async (token) =>{
    const res = await fetch(`${API_URL}/astrologerAPIs/fetch_astrologers_chat`,{
        method:"POST",
        headers:{
            
            'apikey':API_KEY,
            
         "Content-Type": "application/json",
        },      
    });

    return await res.json();
}

// chat api ===================end
// call api ===================start


export const OpenSaveChartApi = async (data) => {
    console.warn(data,'databaseeeeeeeeeeeeeeee')
    const res = await fetch(`${API_URL}/fetch-saved-kundli`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${data?.token}`,
        'apikey': API_KEY,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userid: data?.user }), // Correctly stringify the body object
    });
  
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
  
    return await res.json();
  };
  
   


// call api ===================end

// call api ===================start

export const fetchastrocallData = async (token) =>{
    const res = await fetch(`${API_URL}/astrologerAPIs/fetch_astrologers_call`,{
        method:"POST",
        headers:{
            
            'apikey':API_KEY,
            
         "Content-Type": "application/json",
        },      
    });

    return await res.json();
}

// call api ===================end

// video api ===================start

export const fetchvideoData = async (token) =>{
    const res = await fetch (`${API_URL}/astrologerAPIs/fetch_astrologers_videochat`,{
        method:"POST",
        headers:{
           
            'apikey':API_KEY,
            
         "Content-Type": "application/json",
        }, 
    });
    return await res.json();
}

// video api ===================end


export const allServicesApi = async (data) => {
    try {
        const res = await fetch(`${API_URL}/fetch_expertise`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                 "apikey": API_KEY, // Replace with your actual API key
                 
            },
          
        });

        if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
        }

        return await res.json();
    } catch (error) {
        console.error('Error fetching all services:', error);
        throw error; // Optionally re-throw the error if you want to handle it further up the call chain
    }
};




//All services Api's 







const getAuthorizationHeader = () => {
    const username = REACT_APP_USER_NAME;
    const password =REACT_APP_USER_PASSWORD;
    return 'Basic ' + btoa(username + ':' + password);
};

const handleApiResponse = async (response) => {
    if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
    }
    return await response.json();
};

export const todayHoroscopePredictionApi = async (data) => {
    console.warn(data?.timezone); // Optional: Remove in production if not needed

    try {
        const res = await fetch(`${REACT_APP_JSON_API_URL}/sun_sign_prediction/daily/${data?.horosName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(),
                'Accept-Language': 'hi', // Set language to Hindi
            },
            body: JSON.stringify({ timezone: data?.timezone }) // Include timezone if needed
        });

        return await handleApiResponse(res);
    } catch (error) {
        console.error('Error fetching today\'s horoscope prediction:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const tomorrowHoroscopePredictionApi = async (data) => {
    const lang=data?.language
    try {
        const res = await fetch(`${REACT_APP_JSON_API_URL}/sun_sign_prediction/daily/next/${data?.horosName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'hi', // Set language to Hindi,
                'Authorization': getAuthorizationHeader(),
            },
            body: JSON.stringify({ timezone: data?.timezone }),
        });

        return await handleApiResponse(res);
    } catch (error) {
        console.error('Error fetching tomorrow\'s horoscope prediction:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const monthlyHoroscopePredictionApi = async (data) => {
    try {
        const res = await fetch(`${REACT_APP_JSON_API_URL}/horoscope_prediction/monthly/${data?.horosName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'hi', // Set language to Hindi,
                'Authorization': getAuthorizationHeader(),
            },
            body: JSON.stringify({ timezone: data?.timezone }),
        });

        return await handleApiResponse(res);
    } catch (error) {
        console.error('Error fetching monthly horoscope prediction:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const joinasAstrologer = async (data) => {
    console.warn(data);
    try {
        const url = `${API_URL}/join-as-astrologer`;
        console.warn(url); // Optional: Remove in production if not needed

        const formData = new FormData();

        // Append text fields to FormData
        formData.append('title', data?.title || '');
        formData.append('name', data?.Name || '');
        formData.append('gender', data?.gender || '');
        formData.append('email', data?.email || '');
        formData.append('mobile', data?.mobile || '');
        formData.append('day', data?.day || '');
        formData.append('month', data?.month || '');
        formData.append('year', data?.year || '');
        formData.append('qualification', data?.qualification || '');
        formData.append('expertise', Array.isArray(data?.expertises) ? data.expertises.join(',') : data?.expertises || '');
        formData.append('experience', data?.experience || '');
        formData.append('experiencemonth', data?.experiencemonth || '');
        formData.append('language', Array.isArray(data?.languages) ? data.languages.join(',') : data?.languages || '');
        formData.append('state', data?.state || '');
        formData.append('countrycode', data?.country_code || '');
        formData.append('photo', data?.photo || '');
        formData.append('resume', data?.resume || '');
      
       const bToken=data?.token
       console.log(bToken)

        const res = await fetch(url, {
            method: 'POST',
            headers: {
               
                "apikey": API_KEY, // Replace with your actual API key
                'Authorization': `Bearer ${bToken}` // Include the Bearer token in the header
            },
            
            body: formData
        });

        if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (error) {
        console.error(error); // Optional: Log error details
        return { error: error.message }; // Return a default error response
    }
};



// join as astrologer api end

// terms and condition api start
export const termsConditionApi = async () => {

    try {
        const res = await fetch(`${API_URL}/fetchterms`, {
            method: "GET",
            headers: {
                'apiKey':API_KEY ,
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};
// term and condition api end



// terms and condition api start
export const RefundcancellationApi = async () => {

    try {
        const res = await fetch(`${API_URL}/fetchrefund`, {
            method: "GET",
            headers: {
                'apiKey':API_KEY ,
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};
// term and condition api end



//privacy policy api start
export const privacyPolicyApi = async () => {

    try {
        const res = await fetch(`${API_URL}/fetchprivacy`, {
            method: "GET",
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};
//privacy policy api end


//privacyassurance api start
export const PrivacyAssuranceApi = async () => {

    try {
        const res = await fetch(`${API_URL}/fetchpri-assurance`, {
            method: "GET",
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};
//privacyassurance api end

// Verifiedastrologers api start

export const VerifiedastrologersApi = async () => {

    try {
        const res = await fetch(`${API_URL}/fetchverified`, {
            method: "GET",
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};

// Verifiedastrologers api end

// securepyments api start


export const SecurePymentsApi = async () => {

    try {
        const res = await fetch(`${API_URL}/fetchsecure`, {
            method: "GET",
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};


// securepyments api end

//  Satisfaction api start

export const SatisfactionApi = async () => {

    try {
        const res = await fetch(`${API_URL}/fetchsatishfaction`, {
            method: "GET",
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};

//  Satisfaction api end

// pricing-policy api start 

export const PricingPolicyApi = async () => {

    try {
        const res = await fetch(`${API_URL}/pricing-policy`, {
            method: "GET",
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};

// pricing-policy api end

// about-us-for-website api start

export const AboutUsApi = async () => {

    try {
        const res = await fetch(`${API_URL}/about-us-for-website`, {
            method: "GET",
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};

// about-us-for-website api end

// disclaimer api start

export const DisclaimerApi = async () => {

    try {
        const res = await fetch(`${API_URL}/disclaimer`, {
            method: "GET",
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
                // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
            },
        });

        if (!res.ok) {
            // Detailed error information
            const errorText = await res.text();
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`);
        }

        return await res.json();
    } catch (error) {
        console.error("Error fetching terms and conditions:", error.message); // Log detailed error
        return { error: error.message }; // Return the detailed error message
    }
};

// disclaimer api end

// Utility function for making API calls
const today = new Date();
const defaultData = {
    day: today.getDate(),
    month: today.getMonth() + 1, // Months are zero-based in JavaScript
    year: today.getFullYear(),
    hour: today.getHours(),
    min: today.getMinutes(),
    lat: 25.7,
    lon: 82.6,
    tzone: 5.5
};

const isValidNumber = (value) => !isNaN(value) && isFinite(value);

const validateData = (data) => {
    return (
        isValidNumber(data.day) &&
        isValidNumber(data.month) &&
        isValidNumber(data.year) &&
        isValidNumber(data.hour) &&
        isValidNumber(data.min) &&
        isValidNumber(data.lat) &&
        isValidNumber(data.lon) &&
        isValidNumber(data.tzone)
    );
};

const makeApiCall = async (url, data = {}) => {
   const finalData = {
  day: String(data.day).padStart(2, '0') || String(defaultData.day).padStart(2, '0'),
  month: String(data.month).padStart(2, '0') || String(defaultData.month).padStart(2, '0'),
  year: Number(data.year) || defaultData.year,
  hour: 0,
  min: 0,
  lat: Number(data.lat) || defaultData.lat,
  lon: Number(data.lon) || defaultData.lon,
  tzone: Number(data.tzone) || defaultData.tzone,
};


    if (!validateData(finalData)) {
        console.error('Invalid data:', finalData);
        return { error: 'Invalid data format' };
    }

    console.log('Data to be sent:', finalData);

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'en',
                'Authorization': getAuthorizationHeader(),
            },
            body: JSON.stringify(finalData),
        });

        return await handleApiResponse(res);
    } catch (error) {
        console.error('Error fetching data:', error);
        return { error: error.message || 'Failed to fetch data' };
    }
};



// All Panchang API functions
export const getBasicPanchang = (data) => makeApiCall(`${REACT_APP_JSON_API_URL}/basic_panchang`, data);
export const getBasicPanchangSunrise = (data) => makeApiCall(`${REACT_APP_JSON_API_URL}/basic_panchang/sunrise`, data);
export const getAdvancedPanchang = (data) => makeApiCall(`${REACT_APP_JSON_API_URL}/advanced_panchang`, data);
export const getAdvancedPanchangSunrise = (data) => makeApiCall(`${REACT_APP_JSON_API_URL}/advanced_panchang/sunrise`, data);
export const getPlanetPanchang = (data) => makeApiCall(`${REACT_APP_JSON_API_URL}/advanced_panchang/sunrise`, data);
export const getPlanetPanchangSunrise = (data) => makeApiCall(`${REACT_APP_JSON_API_URL}/planet_panchang/sunrise`, data);
export const getChaughadiyaMuhurta = (data) => makeApiCall(`${REACT_APP_JSON_API_URL}/chaughadiya_muhurta`, data);
export const panchangLagnaTable = (data) => makeApiCall(`${REACT_APP_JSON_API_URL}/panchang_lagna_table`, data);
export const monthlyPanchang = (data) => makeApiCall(`${REACT_APP_JSON_API_URL}/monthly_panchang`, data);


// All Panchang Api 


export const sliderApi = async () => {
    try {
        // Attempt to fetch data from the API
        const res = await fetch(`${API_URL}/fetch_banner`, {
            method: 'GET',
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is not successful (e.g., HTTP status code 4xx or 5xx)
        if (!res.ok) {
            throw new Error('Network response was not ok');
        }

        // Parse the response body as JSON and return it
        return await res.json();
    } catch (error) {
        // If an error occurs during the fetch or parsing
        console.error('There was a problem with the fetch operation:', error);
        throw error; // Throw the error further to handle it elsewhere if needed
    }
};





export const getAstro_details = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latForKundli),
        lon: Number(data?.lonForKundli),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/astro_details`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


export const sadhesati_life_details = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/astro_details`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const sadhesati_Into_details = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/sadhesati_remedies`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const sadhesati_Current_details = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/sadhesati_current_status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


// Remedies
export const rudraksha_suggestion = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/rudraksha_suggestion`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const Gemstones = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/basic_gem_suggestion`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const mantra = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/numero_fav_mantra`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const yantra = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone: Number(data?.timezone)
        // tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/ayanamsha`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


export const kalsarpa_details = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/kalsarpa_details`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const kalsarpa_remedy = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/kalsarpa_remedy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


export const manglik = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/manglik`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


export const manglik_remedy = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/manglik_remedy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const kp_birth_chart = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/kp_birth_chart`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const planetNakshatra = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/planets/extended`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


export const panchang_lagna_table = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/panchang_lagna_table`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


export const panchang_panchadha_maitri = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/panchadha_maitri`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};



export const match_planet_details = async (data) => {
    const sendFinalData = {


    
            m_day: Number(data?.day),
            m_month: Number(data?.month),
            m_year: Number(data?.year),
            m_hour: Number(data?.hours),
            m_min: Number(data?.minutes),
            m_lat: Number(data?.latitude),
            m_lon:  Number(data?.longitude),
            m_tzone: 5.5,
            f_day: Number(data?.day),
            f_month: Number(data?.month),
            f_year: Number(data?.year),
            f_hour: Number(data?.hours),
            f_min: Number(data?.minutes),
            f_lat:Number(data?.latitude),
            f_lon:   Number(data?.longitude),
            f_tzone: 5.5
    
       
    };
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/match_planet_details`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};


export const current_vdasha_all = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latitude),
        lon: Number(data?.longitude),
        tzone:  Number(data?.timezone)
    };
    
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/current_vdasha_all`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};

export const sarvashtak = async (data) => {
    // console.log(data,'sarvashtaksarvashtaksarvashtaksarvashtak')
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.lat),
        lon: Number(data?.lon),
        tzone:  Number(data?.tzone)
    };
    console.log(JSON.stringify(sendFinalData),'sarvashtaksarvashtaksarvashtaksarvashtak')
    
    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/sarvashtak`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};



export const general_ascendant_report = async (data) => {
    const sendFinalData = {
        day: Number(data?.day),        // Ensure these are numbers
        month: Number(data?.month),
        year: Number(data?.year),
        hour: Number(data?.hours),
        min: Number(data?.minutes),
        lat: Number(data?.latForKundli),
        lon: Number(data?.lonForKundli),
        tzone:  Number(data?.timezone)
    };
    
    
    console.warn(sendFinalData); // Optional: Remove in production if not needed

    try {
        const response = await fetch(`${REACT_APP_JSON_API_URL}/general_ascendant_report`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthorizationHeader(), // Ensure this returns a valid token
               
            },
            body: JSON.stringify(sendFinalData) // Send data directly
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get error response text
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Return the result of handleApiResponse function
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching astrology details:', error);
        return { error: 'Failed to fetch data' };
    }
};
// Remedies


export const sadhesati_report = async (data) => {
   
    const userid = REACT_APP_USER_ID;
    const authcode = REACT_APP_AUTH_CODE;

    const formData = new FormData();
    formData.append('d', data?.date || '');
    formData.append('t', data?.time || '');
    formData.append('lat', data?.lat || '');
    formData.append('lon', data?.lon || '');
    formData.append('tz', data?.tz || '');
    formData.append('userid', userid);
    formData.append('authcode', authcode);

    try {
        const response = await fetch(`${REACT_APP_KUNDALI_CLICK_API_URL}/sadesati`, {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
        }

        return await response.json(); // Adjust this based on your actual response format
    } catch (error) {
        console.error('Error fetching data:', error);
        // Optionally handle the error or return a specific error object
        return { error: 'Failed to fetch data', details: error.message };
    }
};






