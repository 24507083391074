import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const GeneratePDFButton = () => {
    const generatePDF = async () => {
        try {
            // Select the HTML element to capture
            const element = document.getElementById('pdf-content');

            // Convert HTML element to canvas with high quality settings
            const canvas = await html2canvas(element, {
                useCORS: true, // Enable cross-origin resource sharing
                scale: 3,      // Increase scale for better quality (higher scale = better quality)
                logging: true, // Enable logging for debugging
            });
            const imgData = canvas.toDataURL('image/png');

            // Create a new jsPDF instance with a size that matches the content
            const doc = new jsPDF({
                orientation: 'portrait', // or 'landscape'
                unit: 'px',
                format: [canvas.width, canvas.height]
            });

            // Add the image to the PDF
            doc.addImage(imgData, 'PNG', 0, 0, canvas.width / 3, canvas.height / 3);

            // Save the PDF
            doc.save('example.pdf');
        } catch (error) {
            console.error('Failed to generate PDF', error);
            const doc = new jsPDF();
            doc.text('Failed to generate PDF due to an error.', 10, 10);
            doc.save('error.pdf');
        }
    };

    return (
        <div>
            {/* Content to include in the PDF */}
            <div id="pdf-content" style={{ padding: '20px', backgroundColor: '#f0f0f0', fontFamily: 'Arial', width: '210mm', height: '297mm' }}>
                <h1 style={{ textAlign: 'center' }}>Hello world!</h1>
                <p style={{ textAlign: 'center' }}>This is a PDF generated in the browser using jsPDF and html2canvas.</p>
                <p style={{ color: 'blue', fontSize: '14px', textAlign: 'center' }}>This text is styled with CSS.</p>
                
                {/* Image to include in the PDF */}
                <img
                    src="" // Replace with your image URL
                    alt="Placeholder"
                    style={{ display: 'block', margin: '20px auto', width: '150px', height: '150px' }}
                />

                <div style={{ textAlign: 'left' }}>
                    <strong>Fetched Data:</strong>
                    <pre style={{ backgroundColor: '#fff', padding: '10px', borderRadius: '5px', border: '1px solid #ddd', whiteSpace: 'pre-wrap' }}>
                        {JSON.stringify({ example: 'data' }, null, 2)}
                    </pre>
                </div>
            </div>
            <button onClick={generatePDF}>Generate PDF</button>
        </div>
    );
};

export default GeneratePDFButton;
