import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Orderhistory.css';

const Orderhistory = () => {
  return (
    <>
      {/* ========= */}
  <Header/>
    {/* ====== */}
   {/*  */}
<section className="order_histroy_section">
  <div className="container">
     {/* Order History title start */}
     <div className="orderhis_title">
                <h1>Order History</h1>
     </div>
            {/* Order History title end */}
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        {/**/}
        <div className="order_histroy_tab_main_div">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="pills-call-tab" data-bs-toggle="pill" data-bs-target="#pills-call" type="button" role="tab" aria-controls="pills-call" aria-selected="true">Call</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-chat-tab" data-bs-toggle="pill" data-bs-target="#pills-chat" type="button" role="tab" aria-controls="pills-chat" aria-selected="false">Chat</button>
            </li> 
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-gift-tab" data-bs-toggle="pill" data-bs-target="#pills-video" type="button" role="tab" aria-controls="pills-gift" aria-selected="false">Video</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-gift-tab" data-bs-toggle="pill" data-bs-target="#pills-gift" type="button" role="tab" aria-controls="pills-gift" aria-selected="false">Gift's</button>
            </li>
          </ul>
          {/**/}
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-call" role="tabpanel" aria-labelledby="pills-call-tab">
              <div className="conatiner">
                <div className="row">
                  {/*col*/}
                  <div className="col-lg-12 col-md-6 col-sm-12">
                    <div className="main_div_chat_div">
                      <h2 style={{textAlign: 'center', color: 'red', padding: 10}}>Data not Found</h2>
                    </div>
                  </div>
                  {/*col*/}
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab">
              <div className="conatiner">
                <div className="row">
                  {/*col*/}
                  <div className="col-lg-12 col-md-6 col-sm-12">
                    <div className="main_div_chat_div">
                      <h2 style={{textAlign: 'center', color: 'red', padding: 10}}>Data not Found</h2>
                    </div>
                  </div>
                  {/*col*/}
                </div>
              </div>
            </div> 
            <div className="tab-pane fade" id="pills-video" role="tabpanel" aria-labelledby="pills-gift-tab">
              <div className="conatiner">
                <div className="row">
                  {/*col*/}
                  <div className="col-lg-12 col-md-6 col-sm-12">
                    <div className="main_div_chat_div">
                      <h2 style={{textAlign: 'center', color: 'red', padding: 10}}>Data not Found</h2>
                    </div>
                  </div>
                  {/*col*/}
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-gift" role="tabpanel" aria-labelledby="pills-gift-tab">
              <div className="conatiner">
                <div className="row">
                  <div className="wallet_trans_di">
                    <table className="table table-bordered table-striped table-sm mt-3" style={{textAlign: 'center'}}>
                      <tbody><tr>
                          <th colSpan={2}>Gifted To</th>
                          <th>Date</th>
                          <th>Amount</th>
                        </tr>
                        <tr>
                          <td><img src="https://healfate.com/astro_docs//8355astro.jpeg" style={{height: 60, width: 60}} alt='User Name' /></td>
                          <td>Akesh Yadav - (<i>#10020</i>)</td>
                          <td>25 Jun 2024 16:44 PM</td>
                          <td>₹ 501.0/-</td>
                        </tr>
                      </tbody></table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/**/}
        </div>
        {/**/}
      </div>
    </div>
  </div>
</section>
<Footer/>
   {/*  */}
    </>
  )
}

export default Orderhistory
