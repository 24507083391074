import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import { Similartopicslider } from '../data/Owlcarouseloptions';
import { removeHtmlTags } from '../components/Url/Url';
import { allServicesApi } from '../AllApi/AllApi';
import LoadingForChat from '../components/LoadingComponent/LoadingForChat';
import LoadingService from '../components/LoadingComponent/LoadingService';

const SimilarTopics = ({ astroUrl }) => {
  const [allAstrologerServiceData, setAllAstrologerServiceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [serviceurl , setServiceurl] = useState();
  useEffect(() => {
    const fetchAllAstrologerServices = async () => {
      const res = await allServicesApi();
      if (res?.success) {
        setAllAstrologerServiceData(res?.data);
        setServiceurl(res?.url);
        setLoading(false);

        
      }
    };

    fetchAllAstrologerServices();
  }, []);

  const truncateDescription = (description) => {
    if (!description) return 'Description not available.';
    const words = description.split(' ');
    return words.slice(0, 7).join(' ') + '...';
  };

  const memoizedSimilarTopics = useMemo(() => {
    return allAstrologerServiceData?.map((ele) => (
      <div className="item" key={ele?.id}>
        <Link to={`/services/${ele?.name?.replace(/\s+/g, '-')}`}>
          <div className="similar_topic_main_div">
            <div className='similar_topic_img_box'>
              <img src={`${serviceurl}${ele?.icon}`} alt={ele?.name} className='img-fluid' />
            </div>
            <h3>{ele?.name}</h3>
            <p className="phone_txt_none">{truncateDescription(removeHtmlTags(ele?.description))}... <span className="readMore">read more</span></p>
          </div>
        </Link>
      </div>
    ));
  }, [allAstrologerServiceData, astroUrl]);

  return (
    loading ? <LoadingService /> : (
      <section className="similar_topic_section">
        <div className="container">
          <div className="similar_topic_title">
            <h5>Read Similar Topics</h5>
            <hr />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="main_div_similar_topic_slider_div">
                <OwlCarousel className="owl-theme" {...Similartopicslider}>
                  {memoizedSimilarTopics}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  );
};

export default SimilarTopics;