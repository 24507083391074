import React from "react";
import { FaShareAlt } from "react-icons/fa";
import { RWebShare } from "react-web-share";
import { useHistory ,useLocation } from 'react-router-dom';

export default function WebShare() {
    const location = useLocation()
    return (
        
        <span>
            
            <RWebShare
                data={{
                  
                    url: location.pathname,
                   
                }}
               
            >
                <a href="">
                    <FaShareAlt style={{color:"#804C35"}}/>
                </a>
            </RWebShare>
        </span>
    );
}