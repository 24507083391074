function LoadingForMobileslider() {  
    return (
      <>
      
     
      <div className="slider_loader_div">     
      <img src={require('../assets/img/mld.jpg')}></img>
      <div className='slider_gif_loader'>
      <div className='heroslider_loader_main_div'>
        <div className='img_loader_div'>
        <img src={require('../assets/img/loaderimg.png')} alt='loader'/>
        <div className='loader_circle'></div>
        </div>
       </div>
      </div>
       </div>
       </>
    
    );
  }
  
  export default LoadingForMobileslider;
  