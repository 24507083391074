import React, { useState } from 'react'
import './Footer.css';
import { Link } from 'react-router-dom';
import { isAuthenticated } from './Url/Url';
import Login from './Login/Login';
// import { Link } from "react-router-dom";

const Footer = () => {
  const [showLoginModal, setShowLoginModal] = useState();
  function toggleLoginModal() {
    setShowLoginModal(!showLoginModal);
  }
  return (
    <>
{/* test */}
    {/* map section  */}
  
  {/* ===================================footer section start =============== */}

  <section className="footer_section desktop_footer">
    <div className="container">
      {/* about footer */}
      <div className="footer_about_div" hidden>
        <h4>About Healfate</h4>
        <p>Healfate is the best astrology website for online Astrology predictions. Talk to Astrologer on call and get answers to all your worries by seeing the future life through Astrology Kundli Predictions from the best Astrologers from India. Get best future predictions related to Marriage, love life, Career or Health over call, chat, query or report.</p>
      </div>
      {/* end */}
      <div className="row">
        {/* col start  */}
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="footer_main_headti">
            <h5>Astrology Services</h5>
          </div>
          <div className="footer_links">
            <ul>
              <li><Link to="/services/tarot"><i className="bx bxs-chevrons-right" /> Tarot</Link></li>
              <li><Link to="/services/psychologist"><i className="bx bxs-chevrons-right" /> Psychologist</Link></li>
              <li><Link to="/services/vastu"><i className="bx bxs-chevrons-right" /> Vastu</Link></li>
              <li><Link to="/services/palmistry"><i className="bx bxs-chevrons-right" /> Palmistry</Link></li>
              <li><Link to="/services/kp-system"><i className="bx bxs-chevrons-right" /> KP System</Link></li>
              <li><Link to="/services/lal-kitab"><i className="bx bxs-chevrons-right" /> Lal Kitab</Link></li>
               {/* <li><Link to="services/bhrigu-samhita"><i className="bx bxs-chevrons-right" /> Bhrigu Samhita</Link></li> */}
              <li><Link to="/services/vedic"><i className="bx bxs-chevrons-right" /> Vedic</Link></li>
              <li><Link to="/services/astrology"><i className="bx bxs-chevrons-right" /> Astrology</Link></li>
              <li><Link to="/numerology"><i className="bx bxs-chevrons-right" /> Numerology</Link></li>
            </ul> 
           
          </div>
        </div>
        {/* col end */}
        {/* col start  */}
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="footer_main_headti">
            <h5>Upcoming Services</h5>
          </div>
          <div className="footer_links">
            <ul>
               <li><Link to="/services/pranayama"><i className="bx bxs-chevrons-right" /> Pranayama</Link></li>
              <li><Link to="/services/yoga-mudras"><i className="bx bxs-chevrons-right" /> Yoga mudras</Link></li>
              { /* <li><Link to="/services/dietician"><i className="bx bxs-chevrons-right" /> Dietician</Link></li> */ }
              <li><Link to="/services/sun-rays-healing"><i className="bx bxs-chevrons-right" /> Sun Rays Healing</Link></li>
              <li><Link to="/services/sound-and-words-healing"><i className="bx bxs-chevrons-right" /> Sound and Words Healing</Link></li>
              <li><Link to="/services/magnet-healing"><i className="bx bxs-chevrons-right" /> Magnet Healing</Link></li>
              <li><Link to="/services/nature-healing"><i className="bx bxs-chevrons-right" /> Nature Healing</Link></li>
              <li><Link to="/services/acupressure-healing"><i className="bx bxs-chevrons-right" /> Acupressure Healing</Link></li>
              {/*<li><Link to="/services/metal-healing"><i className="bx bxs-chevrons-right" /> Metal Healing</Link></li> */}
              <li><Link to="/services/crystal-healing"><i className="bx bxs-chevrons-right" /> Crystal Healing</Link></li>
            </ul>
          </div>
        </div>
        {/* col end */}
        {/* col start  */}
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="footer_main_headti">
            <h5>Services</h5>
          </div>
          <div className="footer_links">
            <ul>
              <li><Link to="/freeKundali"><i className="bx bxs-chevrons-right" /> Kundli</Link></li>
              <li><Link to="/kundliMatching"><i className="bx bxs-chevrons-right" /> Kundli Matching</Link></li>
              <li><Link to="/services/Lal-Kitab"><i className="bx bxs-chevrons-right" /> Lal Kitab</Link></li> 
              <li><Link to="/panchang"><i className="bx bxs-chevrons-right" /> Panchang</Link></li>
              {/* <li><Link to=""><i className="bx bxs-chevrons-right" /> Vedic</Link></li> */}
              {/* <li><Link to="/chat"><i className="bx bxs-chevrons-right" /> Chat With Astrologer</Link></li>
              <li><Link to="/call"><i className="bx bxs-chevrons-right" /> Call to Astrologer</Link></li>
              <li><Link to="/video"><i className="bx bxs-chevrons-right" /> Video to Astrologer</Link></li> */}
              <li><Link to="/JoinAsastrologer"><i className="bx bxs-chevrons-right" /> Join As An Astrologer</Link></li>
              {/* <li><Link to="/Samuhikpuja"><i className="bx bxs-chevrons-right" /> Samuhik Puja</Link></li> */}
               <li><Link to="/Sitemap"><i className="bx bxs-chevrons-right" /> Sitemap</Link></li>
              {/*<li><a href="blogs"><i class='bx bxs-chevrons-right'></i> Current Affairs</a></li>*/}
              {/* <li><Link to="/feedback"><i className="bx bxs-chevrons-right" /> Feedback</Link></li> */}
              {/* <li><Link to="/live"><i className="bx bxs-chevrons-right" /> Live Stream</Link></li> */}
            </ul>
          </div>
        </div>
        {/* col end */}
        {/* col start  */}
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="footer_main_headti">
            <h5>Information</h5>
          </div>
          <div className="footer_links">
            <ul>
              <li><Link to="/refundcancellation"><i className="bx bxs-chevrons-right" /> Refund &amp; Cancellation Policy</Link></li>
              <li><Link to="/termsandconditions"><i className="bx bxs-chevrons-right" /> Terms &amp; Conditions</Link></li>
              <li><Link to="/privacypolicy"><i className="bx bxs-chevrons-right" /> Privacy Policy</Link></li>
              <li><Link to="/disclaimer"><i className="bx bxs-chevrons-right" /> Disclaimer</Link></li>
              {/* <li><Link to="/pricingpolicy"><i className="bx bxs-chevrons-right" /> Pricing Policy</Link></li> */}
              <li><Link to="/aboutus"><i className="bx bxs-chevrons-right" /> About Us</Link></li>
              <li><Link to="/contactus"><i className="bx bxs-chevrons-right" /> Contact Us</Link></li>
              
            </ul>
          </div>
          <div className="footer_conatct_div">
            <div className="footer_middle_headti">
              <h5>Contact us</h5>
            </div>
            {/* media div start */}
            {/* <div className="d-flex align-items-middle">
              <div className="flex-shrink-0">
                <i className="bx bx-support" />
              </div>
              <div className="flex-grow-1 ms-2">
                <div className="footer_num_div">
                  <p><Link to="/ticket">  We are available 24x7 on support, <span>click to raise ticket.</span></Link></p>
                </div>
              </div>
            </div> */}
            {/* media div end */}
            {/* media div start */}
            <div className="d-flex align-items-middle">
              <div className="flex-shrink-0">
                <i className="bx bx-envelope" />
              </div>
              <div className="flex-grow-1 ms-2">
                <div className="footer_num_div">
                  <b>Email ID:</b>
                  <a href="mailto:contact@talkndheal.com">  contact@talkndheal.com</a>
                </div>
              </div>
            </div>
            {/* media div end */}
            {/* socila icons div */}
            <div className="footer_social_ic">
              <a target="_blank" href="https://www.facebook.com/talkndhealofficial/" className="fa fa-facebook" />
              <a target="_blank" href="https://www.instagram.com/talkndhealdotcom/" className="fa fa-instagram" />
              <a target="_blank" href="https://www.linkedin.com/company/talk-nd-heal/" className="fa fa-linkedin" />
              <a target="_blank" href="https://www.youtube.com/@TalkndHeal" className="fa fa-youtube" />
              <a target="_blank" href="https://x.com/TalkndHeal" className="fa-brands fa-x-twitter" />
              <a target="_blank" href="https://in.pinterest.com/talkndheal/" className="fa fa-pinterest" />
            </div>
            
            <div className="footer_app_img">
              {/* <Link to="/"><img src="../../images/android01.png" alt /></Link>
              <Link to="/"><img src="../../images/appstore.png" alt /></Link> */}
            </div>
          </div>
        </div>
        {/* col end */}

       {/*col start*/}
<div className="col-lg-12">
  <div className="secure_div_footer">
    <h5><i className="bx bx-lock-alt" /> Private &amp; Confidential</h5>
    <h5><i className="bx bxs-user-check" /> Verified Astrologers</h5>
    <h5><i className="bx bxs-wallet-alt" /> Secure Payments</h5>
  </div>
</div>
{/*col end*/}

      </div>
    </div>
  </section>
  {/* ===================================footer section end =============== */}
  {/* ===================================copyright section start================= */}
  <section className="copyright_section_sec">
    <div className="container">
      <div className="row">
        {/* col start  */}
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <p>© All copyrights reserved 2024 <span>Talk nd Heal  | Powered By Immortal Varta</span></p>
        </div>
        {/* col end */}
      </div>
    </div>
  </section>
  {/* ===================================copyright section end================= */}

{/* desktop footer icons div */}
<div className="desktop_fix_icon_div">
 
  <div className="desktop_btm_fx_flx">
    <Link to="/call" className="call_ft">
      <i className="fa fa-mobile-alt" /> 
      <span> Call</span>
    </Link>
    <Link to="/chat" className="chat_ft">
      <i className="fas fa-comment-dots" /> 
      <span> Chat</span>

    </Link>
    <Link to="/video" className="live_ft">
      <i className="fa fa-video-camera" /> 
      <span> Video</span>
    </Link>
    <Link to="/live" className="live_stream">
      <i className='bx bx-station bx-tada bx-rotate-180' />
      
      <span> Live</span>
    </Link>
    
  
  </div>
</div>
{/* desktop footer icons div */}


{/*===========mobile footer fixed call chat icons ============== */}
<div className="main_div_footer_fxd_chat">
  <div className="main_div_flx_div_footer">
    {/* home icon */}
    <div className="home_icon_div">
      <a href="/">
        <span><img src="../../images/homenormal.svg" alt="home" /></span>
        <b>Home</b>
      </a>
    </div>
    {/* home icon end*/}
    {/* 4 div icon */}
    <div className="btn_type_icon_div">
      <a href="javascript:void(0);">
        <span> <img src="../../images/chatnormal.svg" alt="chat" /></span>
        <b>Chat</b>
      </a>
      <a href="javascript:void(0);">
        <span><img src="../../images/callnormal.svg" alt="call" /></span>
        <b>Call</b>
      </a>
      <a href="javascript:void(0);">
        <span><img src="../../images/vcallnormal.svg" alt="video" /></span>
        <b>Video</b>
      </a>

      <a href="javascript:void(0);">
        <span><img src="../../images/Live.svg" alt="live" /></span>
        <b>Live</b>
      </a>

   
     
    </div>
    {/* 4 div icon end */}
   
    {/* profile icon */}
   {!isAuthenticated? <div className="home_icon_div">
      <a onClick={toggleLoginModal}>
        <span><img src="../../images/profilenormal.svg" alt="profile" /></span>
        <b>Profile</b>
      </a>
    </div>: <div className="home_icon_div">
      <a href="/userprofile">
        <span><img src="../../images/profilenormal.svg" alt="profile" /></span>
        <b>Profile</b>
      </a>
    </div>}
   
    {/* profile icon end*/}
  </div>

     {/*  */}
<div className='overlay_comming_txt'>
  <p>Coming Soon</p>
</div>
      {/*  */}
</div>
{/*=========== mobile footer fixed call chat icons ============== */}

{ showLoginModal && <Login closeModal={toggleLoginModal} />}

    </>
  )
}

export default Footer
